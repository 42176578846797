<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && project == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title" v-if="project.CustomerMini.CompanyName">{{ project.CustomerMini.CompanyName }}<span class="yellow-dot">.</span></div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <div class="title-sum">SUMMARY</div>
          <div class="form-table">
            <el-row :gutter="24">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-boxes"></i>
                  <span>Product</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="20">
                <div class="content">
                  <span>{{ project.Product.Title }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="24">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-barcode"></i>
                  <span>SKU</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8">
                <div class="content">
                  <el-input @change="updateSKU" v-model="project.Product.SKU" placeholder="..." :disabled="![10].includes(user.Role)" class="name-input"> </el-input>
                </div>
                <span class="error-validation">{{ $errorMessage("SKU", projectValidation) }}</span>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="24" v-if="[10,3].includes(user.Role)">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-barcode"></i>
                  <span>Product Id</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8">
                <div class="content">
                  <el-input @change="updateProductId" v-model="project.Product.ProductId" placeholder="..."  class="name-input"> </el-input>
                </div>
                <span class="error-validation">{{ $errorMessage("ProductId", projectValidation) }}</span>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="24">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-pallet"></i>
                  <span>Quantity</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="20">
                <div class="content">
                  <span>{{ project.Quantity }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="line" v-if="!project.MainJob"></div>

            <el-row :gutter="24" v-if="!project.MainJob">
              <el-col class="input-container" :sm="4" :xs="12">
                <div class="left">
                  <i class="icon-peoplearrows"></i>
                  <span>Supplier</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8" :xs="12">
                <el-select filterable v-model="project.SupplierId" placeholder="Select" @change="changeSupplier" :disabled="[2].includes(user.Role)">
                  <el-option v-for="(item, index) in suppliersList.filter((x) => x.IsDeactivated != true && x.SupplierType==0)" :key="index" :value="item.ID" :label="item.Title"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <div class="line" ></div>

            <el-row :gutter="24">
              <el-col class="input-container" :sm="4" :xs="12">
                <div class="left">
                  <i class="icon-calendarplus"></i>
                  <span>Starting Date</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8" :xs="12">
                <div class="content">
                  <el-date-picker format="dd-MM-yyyy" :clearable="false" prefix-icon="icon-calendarplus" @change="changeStartingDate" v-model="project.StartingDate" :disabled="[2].includes(user.Role)" type="date" placeholder="..."></el-date-picker>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>

            <el-row :gutter="24">
              <el-col class="input-container" :sm="4" :xs="12">
                <div class="left">
                  <i class="icon-hourglass"></i>
                  <span>Timeline</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8" :xs="12">
                <div class="content">
                  <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" @change="changeTimeline" v-model="project.TimeLine" :picker-options="pickerOptions" :disabled="[2].includes(user.Role)" type="date" placeholder="..."></el-date-picker>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>

            <el-row :gutter="24">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-usertie"></i>
                  <span>Product Manager</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="8" :xs="12">
                <el-select v-model="project.ProductManagerId" placeholder="Select" @change="changeProductManager" :disabled="[2].includes(user.Role) || !project.MainJob">
                  <el-option v-for="(item, index) in getUserList.filter((x) => (x.Role == 10 || x.Role == 3) && x.IsDeactivated == false)" :key="index" :value="item.ID" :label="item.Title" > </el-option>
                </el-select>
              </el-col>
            </el-row>
             <div class="line" ></div>
            <el-row :gutter="24" >
             
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-bars-staggered"></i>
                  <span>Stage</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" >
                <div class="status primary">
                  <span v-if="getEnums"> Design </span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob">
                <div class="status primary">
                  <span v-if="getEnums"> Production </span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob">
                <div class="status primary">
                  <span v-if="getEnums"> Operation </span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob && ![2].includes(user.Role)">
                <div class="status primary">
                  <span v-if="getEnums"> Account </span>
                </div>
              </el-col>
              <!-- <el-col class="input-container" :sm="4">
                <div class="status primary">
                  <span v-if="getEnums"> Quality Control</span>
                </div>
              </el-col> -->
            </el-row>
            <div class="line" ></div>
            <el-row :gutter="24" >
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-hashtag"></i>
                  <span>Status</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" >
                <div class="status" :class="getClassDesingStatus(project.JobDesingStatus)">
                  <span v-if="getEnums">{{ getEnumsDisplay("JobDesingStatus", project.JobDesingStatus) }}</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob">
                <div class="status" :class="getClassProductionStatus(project.JobProductionStatus)">
                  <span v-if="getEnums">{{ getEnumsDisplay("JobProductionStatus", project.JobProductionStatus) }}</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob">
                <div class="status" :class="getClassOperationStatus(project.JobOperationStatus)">
                  <span v-if="getEnums">{{ getEnumsDisplay("JobOperationStatus", project.JobOperationStatus) }}</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="4" v-if="!project.MainJob && ![2].includes(user.Role)">
                <div v-for="(item, index) in project.Operation.Shipments" :key="index" class="status" :class="getClassAccountStatus(item.IsSupplierInvoiceSaged)">
                  <span v-if="item.IsSupplierInvoiceSaged">{{$moment(item.CollectionDate).format("DD-MM-YYYY")}} Saged</span>
                  <span v-else>{{$moment(item.CollectionDate).format("DD-MM-YYYY")}} Pending</span>
                </div>
                <!-- <div class="status" :class="getClassAccountStatus(project.JobAccountStatus)">
                  <span v-if="getEnums">{{ getEnumsDisplay("JobAccountStatus", project.JobAccountStatus) }}</span>
                </div> -->
              </el-col>
              <!-- <el-col class="input-container" :sm="4">
                <div class="status" :class="getClassQualityControlStatus(project.JobQualityControlStatus)">
                  <span v-if="getEnums">{{ getEnumsDisplay("JobQualityControlStatus", project.JobQualityControlStatus) }}</span>
                </div>
              </el-col> -->
            </el-row>
            <div class="line"></div>
            <el-row :gutter="24">
              <el-col class="input-container" :sm="4">
                <div class="left">
                  <i class="icon-clock-rotate"></i>
                  <span>Last Update</span>
                </div>
              </el-col>
              <el-col class="input-container" :sm="20">
                <div class="content">
                  <span>{{ $moment(project.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
          </div>
          <el-tabs type="border-card" :stretch="true" @tab-click="clearNoteText">
            <!-- <el-tab-pane v-if="user.Role != 2">
              <span slot="label"><i class="icon-receipt"></i> Offer</span>
              <div class="offer form">
                <div class="title">Quotation<span class="yellow-dot">.</span></div>
                <el-table v-if="quotation" :data="quotationList" style="width: 100%">
                  <el-table-column prop="OfferNo" label="Offer No" width="180"> </el-table-column>
                  <el-table-column prop="Customer.CompanyName" label="Customer"></el-table-column>
                  <el-table-column prop="date" label="Date">
                    <template slot-scope="scope">
                      <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
                      <br />
                      <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="Status">
                    <template slot-scope="scope">
                      <div class="status" :class="getClassOfferStatus(scope.row.Status)">
                        <span v-if="getEnums">{{ getEnumsDisplay("OfferStatus", scope.row.Status) }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Account Manager">
                    <template slot-scope="scope"> {{ scope.row.AccountManager.FullName }} </template>
                  </el-table-column>
                </el-table>
                <div v-if="!quotation" class="no-data">No Data</div>

                <div class="line"></div>
                <div class="comment-body">
                  <div class="comments form">
                    <div class="title">Comments<span class="yellow-dot">.</span></div>
                    <div class="comment-container" v-for="comment in project.Notes.filter((x) => x.Type == 0)" :key="comment.ID">
                      <div class="line"></div>
                      <div class="content">
                        <div class="comment-top">
                          <div class="left">
                            <div class="circle">{{ comment.User.FirstName[0] + comment.User.LastName[0] }}</div>
                            <div class="personel-infos">
                              <div class="user-name">{{ comment.User.FirstName + " " + comment.User.LastName }}</div>
                              <div class="status">
                                <span>{{ getEnumsDisplay("TypeOfAuthority", comment.User.Role) }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                        <div class="comment-content">{{ comment.Content }}</div>
                        <div class="comment-date">{{ $moment(comment.CreatedDate).format("DD-MM-YYYY") }}</div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="comment-container">
                      <div class="comment-text">
                        <div class="left">
                          <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                        </div>
                        <div class="textarea-group">
                          <Mentionable :keys="['@']" :items="items" @apply="onApply(...arguments)" offset="6">
                            <textarea v-model="noteText" :rows="7" placeholder="Add a Comment.." />
                          </Mentionable>
                          <div>use @ to mention someone</div>
                        </div>
                        <el-button @click="comment(0)">Comment</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane> -->
            <el-tab-pane v-if="[10,2,6].includes(user.Role) ">
              <span slot="label"><i class="icon-palette"></i> Design</span>
              <div class="design form">
                <!-- <div class="form" style="padding: 10px; margin-top: 0">
                  <el-alert title="Warning!" type="error" description="No design sample available for this product." show-icon> </el-alert>
                  <div class="title" style="color: #f56c6c">Design Sample Request</div>
                  <div style="display: flex; justify-content: space-between; align-items: center">
                    <el-checkbox v-model="project.SampleRequest" @change="sampleRequestSave">Request a design sample.</el-checkbox>
                    <el-input v-model="project.SampleRequestNote" @change="sampleRequestSave" style="width: 50%"></el-input>
                  </div>
                </div>
                <div class="line"></div>v-if="!project.MainJob && project.SupplierId!=null"-->

                <div class="title" v-if="!project.MainJob && project.SupplierId!=null">Design Preview</div>
                <div class="upload" v-if="!project.MainJob && project.SupplierId!=null" >
                  <el-upload
                    class="upload-demo"
                    :class="{ disable: project.SupplierDesignInformation.PreviewFile.Url != null ? true : false }"
                    :disabled="project.SupplierDesignInformation.PreviewFile.Url != null ? true : false"
                    drag
                    :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                    :show-file-list="false"
                    :on-success="handlePreviewSuccess"
                    :headers="{
                      Authorization: $client.defaults.headers.common['Authorization'],
                    }"
                  >
                    <i class="icon-cloudarrow"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <!-- <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div> -->
                  </el-upload>
                  <span class="error-validation">{{ $errorMessage("DesignPreviewFile", projectValidation) }}</span>

                  <div class="review" v-if="project.SupplierDesignInformation.PreviewFile.Url">
                    <img :src="project.SupplierDesignInformation.PreviewFile.Url" alt="" />
                  </div>
                  <div class="all-files" v-if="project.SupplierDesignInformation.PreviewFile.Url">
                    <div class="files">
                      <div class="file">
                        <div class="file-icon">
                          <img :src="getExtension(project.SupplierDesignInformation.PreviewFile.FileName)" />
                        </div>
                        <div class="filecontent">
                          <div class="file-name">{{ project.SupplierDesignInformation.PreviewFile.FileName }}</div>
                          <div class="file-size">{{ fileSize(project.SupplierDesignInformation.PreviewFile.FileSize) }}</div>
                        </div>
                      </div>
                      <div class="icon-status">
                        <div class="icon-status">
                          <a :href="project.SupplierDesignInformation.PreviewFile.Url" target="_blank"><i class="icon-download"></i></a>
                        </div>
                        <div class="icon-status" @click="clearPreviewPhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="line"></div>
                  <div class="draft">
                    <el-button :disabled="project.Stage != 3 ? true : false" type="info" class="add-btn" @click="saveDesignPreview" :loading="loading == 'designPreview'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                  </div> -->
                </div>
                <div class="line"></div>
                <el-collapse accordion>
                  <el-collapse-item>
                    <template slot="title"> Customer Design Details<span class="yellow-dot">.</span></template>
                    <div class="form">
                      <el-row :gutter="24">
                        <el-col :sm="12">
                        <div class="title">Customer Sample Photo</div>
                        <br>
                        <div class="upload">
                            <el-upload
                              class="upload-demo"
                              :class="{ disable: project.DesignInformation.SamplePhoto.Url != null ? true : false }"
                              :disabled="project.DesignInformation.SamplePhoto.Url != null ? true : false"
                              drag
                              :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                              :show-file-list="false"
                              :on-success="handleCustomerSamplePhotoSuccess"
                              :headers="{
                                Authorization: $client.defaults.headers.common['Authorization'],
                              }"
                            >
                              <i class="icon-cloudarrow"></i>
                              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                              <!-- <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div> -->
                            </el-upload>
                            <span class="error-validation">{{ $errorMessage("DesignCustomerSamplePhoto", projectValidation) }}</span>
                            
                            <div class="all-files" v-if="project.DesignInformation.SamplePhoto.Url">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(project.DesignInformation.SamplePhoto.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ project.DesignInformation.SamplePhoto.FileName }}</div>
                                    <div class="file-size">{{ fileSize(project.DesignInformation.SamplePhoto.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <div class="icon-status">
                                    <a :href="project.DesignInformation.SamplePhoto.Url" target="_blank"><i class="icon-download"></i></a>
                                  </div>
                                  <div class="icon-status" @click="clearCustomerSamplePhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                                </div>
                              </div>
                            </div>
                          </div>
                       </el-col>
                       <el-col :sm="12">
                        <div class="upload">
                          <div class="review-sample-photo" v-if="project.DesignInformation.SamplePhoto.Url">
                            <img :src="project.DesignInformation.SamplePhoto.Url" alt="" />
                          </div>
                        </div>
                       </el-col>
                      </el-row>
                      <div class="line"></div>
                      <el-row :gutter="24" style="align-items: start">
                       <el-col :sm="12" class="design-left-container">
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Product Reference</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.DesignInformation.ProductReference"></el-input>
                              <span class="error-validation">{{ $errorMessage("ProductReference", projectValidation) }}</span>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Size</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input disabled :value="getSizeString()"></el-input>
                            </el-col>
                          </el-row>
                        <!--   <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Production Size</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.DesignInformation.Size"></el-input>
                            </el-col>
                          </el-row> -->

                          <!-- <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Raw Metarial</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.DesignInformation.RawMaterial"></el-input>
                              <span class="error-validation">{{ $errorMessage("RawMaterial", projectValidation) }}</span>
                            </el-col>
                          </el-row> -->
                          <el-row :gutter="24" v-for="(item, index) in project.DesignInformation.PantoneColors" :key="index">
                            <el-col :span="12">
                              <div class="text"><i class="icon-trash-can-solid" @click="project.DesignInformation.PantoneColors.splice(index, 1)"></i> Pantone Color {{ index + 1 }}</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.DesignInformation.PantoneColors[index]"></el-input>
                              <span class="error-validation">{{ $errorMessage("PantoneColors", projectValidation) }}</span>
                            </el-col>
                          </el-row>
                          <el-row style="justify-content: start">
                            <el-button type="primary" @click="addNewCustomerColor" class="add-btn"><i class="icon-plus-solid"></i> Add Color</el-button>
                          </el-row>
                        </el-col>
                        <el-col :sm="12" class="right-card">
                          <div class="inline-form">
                            <div class="input-group">
                              <div class="left">Customer Design Confirmation Date:</div>
                              <div class="right">{{ $moment(project.DesignInformation.DesignDate).format("DD-MM-YYYY") }}</div>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                      <!-- <div class="line"></div>
                      <div class="title">Details<span class="yellow-dot">.</span></div>

                      <el-row :gutter="24">
                        <el-col :span="24">
                          <el-input type="textarea" v-model="project.DesignInformation.Details" :rows="4"></el-input>
                        </el-col>
                      </el-row> -->
                      <div class="documents">
                        <div class="upload">
                          <el-upload
                            class="upload-demo"
                            :class="{ disable: project.DesignInformation.File != null ? true : false }"
                            :disabled="project.DesignInformation.File != null ? true : false"
                            drag
                            :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                            :show-file-list="false"
                            :on-success="handleCustomerSuccess"
                            :headers="{
                              Authorization: $client.defaults.headers.common['Authorization'],
                            }"
                          >
                            <i class="icon-cloudarrow"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 25MB</div> -->
                          </el-upload>
                          <span class="error-validation">{{ $errorMessage("File", projectValidation) }}</span>

                          <div class="review" v-if="project.DesignInformation.File">
                            <img :src="project.DesignInformation.File.Url" alt="" />
                          </div>
                          <div class="all-files" v-if="project.DesignInformation.File">
                            <div class="files">
                              <div class="file">
                                <div class="file-icon">
                                  <img :src="getExtension(project.DesignInformation.File.FileName)" />
                                </div>
                                <div class="filecontent">
                                  <div class="file-name">{{ project.DesignInformation.File.FileName }}</div>
                                  <div class="file-size">{{ fileSize(project.DesignInformation.File.FileSize) }}</div>
                                </div>
                              </div>
                              <div class="icon-status">
                                <div class="icon-status">
                                  <a :href="project.DesignInformation.File.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                                <div class="icon-status" @click="project.DesignInformation.File = null"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                              </div>
                            </div>
                          </div>
                          <div class="line"></div>
                        </div>
                        <div class="mini-form checkboxes">
                            <div class="title">Customer Confirmation<span class="yellow-dot">.</span></div>
                            <el-row :gutter="24">
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.DieCut">Die Cut</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.DieCut", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.ProductDimensions">Product Dimensions</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.ProductDimensions", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.PantoneColoursCodes">Pantone Colour Codes</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.PantoneColoursCodes", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.Positioning">Positioning</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.Positioning", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row  :gutter="24">
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.Gsm">Gsm</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.Gsm", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.Lamination">Lamination</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.Lamination", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.Handle">Handle</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.Handle", projectValidation) }}</span>
                              </el-col>
                              <el-col class="input-container" :sm="6">
                                <el-checkbox v-model="project.DesignInformation.Type">Type</el-checkbox>
                                <span class="error-validation">{{ $errorMessage("DesignInformation.Type", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                          </div>
                        <div class="line"></div>
                        <div class="title">Notes<span class="yellow-dot">.</span></div>
                        <el-row :gutter="24">
                          <el-col :span="24">
                            <el-input type="textarea" v-model="project.DesignInformation.DesignNote" :rows="4"></el-input>
                          </el-col>
                        </el-row>
                        <div class="draft">
                          <el-button  type="info" class="add-btn" @click="saveDesignInformation" :loading="loading == 'customerDesignDetails'" :disabled=" [6,1].includes(user.Role)"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                    </div>   <!-- [1,2,4].includes(project.JobDesingStatus) || -->
                      </div>
                    </div>
                  </el-collapse-item>
                  <!-- project.SupplierId!=null v-if="!project.MainJob " && project.SupplierId!=null-->
                  <el-collapse-item v-if="!project.MainJob && project.SupplierId!=null">
                    <template slot="title"> Supplier Design Details<span class="yellow-dot">.</span></template>
                    <div class="form">
                      <el-row :gutter="24">
                        <el-col :sm="12">
                        <div class="title">Supplier Sample Photo</div>
                        <br>
                        <div class="upload">
                            <el-upload
                              class="upload-demo"
                              :class="{ disable: project.SupplierDesignInformation.SamplePhoto.Url != null ? true : false }"
                              :disabled="project.SupplierDesignInformation.SamplePhoto.Url != null ? true : false"
                              drag
                              :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                              :show-file-list="false"
                              :on-success="handleSupplierSamplePhotoSuccess"
                              :headers="{
                                Authorization: $client.defaults.headers.common['Authorization'],
                              }"
                            >
                              <i class="icon-cloudarrow"></i>
                              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                              <!-- <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div> -->
                            </el-upload>
                            <span class="error-validation">{{ $errorMessage("DesignSupplierSamplePhoto", projectValidation) }}</span>
                            
                            <div class="all-files" v-if="project.SupplierDesignInformation.SamplePhoto.Url">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(project.SupplierDesignInformation.SamplePhoto.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ project.SupplierDesignInformation.SamplePhoto.FileName }}</div>
                                    <div class="file-size">{{ fileSize(project.SupplierDesignInformation.SamplePhoto.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <div class="icon-status">
                                    <a :href="project.SupplierDesignInformation.SamplePhoto.Url" target="_blank"><i class="icon-download"></i></a>
                                  </div>
                                  <div class="icon-status" @click="clearSupplierSamplePhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                                </div>
                              </div>
                            </div>
                          </div>
                       </el-col>
                       <el-col :sm="12">
                        <div class="upload">
                          <div class="review-sample-photo" v-if="project.SupplierDesignInformation.SamplePhoto.Url">
                            <img :src="project.SupplierDesignInformation.SamplePhoto.Url" alt="" />
                          </div>
                        </div>
                       </el-col>
                      </el-row>
                      <div class="line"></div>
                      <el-row :gutter="24" style="align-items: start">
                        <el-col :sm="12" class="design-left-container">
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Product Reference</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.SupplierDesignInformation.ProductReference"></el-input>
                               <span class="error-validation">{{ $errorMessage("ProductReference", projectValidation) }}</span>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Size</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input disabled :value="getSizeString()"></el-input>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Production Size</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.SupplierDesignInformation.Size"></el-input>
                            </el-col>
                          </el-row>
                          <!-- <el-row :gutter="24">
                            <el-col :span="12">
                              <div class="text">Raw Metarial</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.SupplierDesignInformation.RawMaterial"></el-input>
                              <span class="error-validation">{{ $errorMessage("RawMaterial", projectValidation) }}</span>
                            </el-col>
                          </el-row> -->
                          <el-row :gutter="24" v-for="(item, index) in project.SupplierDesignInformation.PantoneColors" :key="index">
                            <el-col :span="12">
                              <div class="text"><i class="icon-trash-can-solid" @click="project.SupplierDesignInformation.PantoneColors.splice(index, 1)"></i> Pantone Color {{ index + 1 }}</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.SupplierDesignInformation.PantoneColors[index]"></el-input>
                            </el-col>
                          </el-row>
                          <el-row style="justify-content: start">
                            <el-button type="primary" @click="addNewSupplierColor" class="add-btn" ><i class="icon-plus-solid"></i> Add Color</el-button>
                          </el-row>
                        </el-col>
                        <el-col :sm="12" class="right-card">
                          <div class="inline-form">
                            <div class="input-group">
                              <div class="left">Supplier Design Confirmation Date:</div>
                              <div class="right">{{ $moment(project.DesignInformation.DesignDate).format("DD-MM-YYYY") }}</div>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                      <!-- <div class="line"></div>

                      <div class="title">Details<span class="yellow-dot">.</span></div>
                      <el-row :gutter="24">
                        <el-col :span="24">
                          <el-input type="textarea" v-model="project.SupplierDesignInformation.Details" :rows="4"></el-input>
                        </el-col>
                      </el-row> -->

                      <div class="documents">
                        <div class="upload">
                          <el-upload
                            class="upload-demo"
                            :class="{ disable: project.SupplierDesignInformation.File != null ? true : false }"
                            :disabled="project.SupplierDesignInformation.File != null ? true : false"
                            drag
                            :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                            :show-file-list="false"
                            :on-success="handleSupplierSuccess"
                            :headers="{
                              Authorization: $client.defaults.headers.common['Authorization'],
                            }"
                          >
                            <i class="icon-cloudarrow"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 25MB</div> -->
                          </el-upload>
                          <span class="error-validation">{{ $errorMessage("File", projectValidation) }}</span>
                          <div class="review" v-if="project.SupplierDesignInformation.File">
                            <img :src="project.SupplierDesignInformation.File.Url" alt="" />
                          </div>
                          <div class="all-files">
                            <div class="files" v-if="project.SupplierDesignInformation.File">
                              <div class="file">
                                <div class="file-icon">
                                  <img :src="getExtension(project.SupplierDesignInformation.File.FileName)" />
                                </div>
                                <div class="filecontent">
                                  <div class="file-name">{{ project.SupplierDesignInformation.File.FileName }}</div>
                                  <div class="file-size">{{ fileSize(project.SupplierDesignInformation.File.FileSize) }}</div>
                                </div>
                              </div>
                              <div class="icon-status">
                                <div class="icon-status">
                                  <a :href="project.SupplierDesignInformation.File.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                                <div class="icon-status" @click="clearSupplierDesignInformation"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                              </div>
                            </div>
                          </div>
                          <div class="line"></div>
                        </div>
                      </div>
                      <div class="mini-form checkboxes">
                        <div class="title">Supplier Confirmation<span class="yellow-dot">.</span></div>
                        <el-row  :gutter="24">
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.DieCut">Die Cut</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.DieCut", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.ProductDimensions">Product Dimensions</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.ProductDimensions", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.PantoneColoursCodes">Pantone Colour Codes</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.PantoneColoursCodes", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.Positioning">Positioning</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.Positioning", projectValidation) }}</span>
                          </el-col>
                        </el-row>
                        <el-row :gutter="24">
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.Gsm">Gsm</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.Gsm", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.Lamination">Lamination</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.Lamination", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.Handle">Handle</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.Handle", projectValidation) }}</span>
                          </el-col>
                          <el-col class="input-container" :sm="6">
                            <el-checkbox v-model="project.SupplierDesignInformation.Type">Type</el-checkbox>
                            <span class="error-validation">{{ $errorMessage("SupplierDesignInformation.Type", projectValidation) }}</span>
                          </el-col>
                        </el-row>
                        <!-- <span class="error-validation">{{ $errorMessage("Logo", projectValidation) }}</span> -->
                      </div>
                      <div class="line"></div>
                      <div class="title">Notes<span class="yellow-dot">.</span></div>
                      <el-row :gutter="24">
                        <el-col :span="24">
                          <el-input type="textarea" v-model="project.SupplierDesignInformation.DesignNote" :rows="4"></el-input>
                        </el-col>
                      </el-row>
                      <div class="draft">
                        <el-button type="info" class="add-btn" @click="saveSupplierDesignInformation" :loading="loading == 'supplierDesignDetails'" :disabled=" [6,1].includes(user.Role)"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                      </div>
                    </div> <!--[2,4].includes(project.JobDesingStatus) || -->
                  </el-collapse-item>

                  <!-- <el-collapse-item>
                    <template slot="title"> Production Photo<span class="yellow-dot">.</span></template>
                    <div class="upload">
                      <el-upload
                        class="upload-demo"
                        :class="{ disable: (project.ProductionPhoto.Url && project.ProductionPhoto) != null ? true : false }"
                        :disabled="project.ProductionPhoto.Url != null ? true : false"
                        drag
                        :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                        :show-file-list="false"
                        :on-success="handlePhotoSuccess"
                        :headers="{
                          Authorization: $client.defaults.headers.common['Authorization'],
                        }"
                        multiple
                      >
                        <i class="icon-cloudarrow"></i>
                        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div>
                      </el-upload>
                      <span class="error-validation">{{ $errorMessage("ProductionPhoto", projectValidation) }}</span>

                      <div class="review" v-if="project.ProductionPhoto.Url">
                        <img :src="project.ProductionPhoto.Url" alt="" />
                      </div>
                      <div class="all-files" v-if="project.ProductionPhoto.Url">
                        <div class="files">
                          <div class="file">
                            <div class="file-icon">
                              <img :src="getExtension(project.ProductionPhoto.FileName)" />
                            </div>
                            <div class="filecontent">
                              <div class="file-name">{{ project.ProductionPhoto.FileName }}</div>
                              <div class="file-size">{{ fileSize(project.ProductionPhoto.FileSize) }}</div>
                            </div>
                          </div>
                          <div class="icon-status">
                            <div class="icon-status">
                              <a :href="project.ProductionPhoto.Url" target="_blank"><i class="icon-download"></i></a>
                            </div>
                            <div class="icon-status" @click="clearDesignPhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                          </div>
                        </div>
                      </div>
                      <div class="mini-form checkboxes">
                        <div class="title">Production Confirmation<span class="yellow-dot">.</span></div>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.PantoneColoursCodes">Pantone Colour Codes</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.ProductDimensions">Product Dimensions</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.MainDesign">Main Design</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.Paperweight">Paperweight</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.Logo">Packaging Source Logo</el-checkbox>
                        <span class="error-validation">{{ $errorMessage("Logo", projectValidation) }}</span>
                      </div>
                      <div class="line"></div>
                      <div class="draft">
                        <el-button type="info" class="add-btn" @click="saveDesignPhoto" :loading="loading == 'productionPhoto'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                      </div>
                      <div class="line"></div>
                    </div>
                  </el-collapse-item> -->
                  <!-- <div class="footerbutton" style="margin-bottom: 24px">
                    <el-button type="primary" class="add-btn" @click="completedDesign" :disabled="[3].includes(project.JobDesingStatus)"><i class="icon-check-double-solid"></i> Completed Design</el-button>
                  </div> -->
                </el-collapse>
                <div class="comment-body">
                  <div class="comments form">
                    <div class="title">Comments<span class="yellow-dot">.</span></div>
                    <div class="comment-container" v-for="comment in project.Notes.filter((x) => x.Type == 1)" :key="comment.ID">
                      <div class="line"></div>
                      <div class="content">
                        <div class="comment-top">
                          <div class="left">
                            <div class="circle">{{ comment.User.FirstName[0] + comment.User.LastName[0] }}</div>
                            <div class="personel-infos">
                              <div class="user-name">{{ comment.User.FirstName + " " + comment.User.LastName }}</div>
                              <div class="status">
                                <span>{{ getEnumsDisplay("TypeOfAuthority", comment.User.Role) }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                        <div class="comment-content">{{ comment.Content }}</div>
                        <div class="comment-date">{{ $moment(comment.CreatedDate).format("DD-MM-YYYY") }}</div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="comment-container">
                      <div class="comment-text">
                        <div class="left">
                          <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                        </div>
                        <div class="textarea-group">
                          <Mentionable :keys="['@']" :items="items" @apply="onApply(...arguments)" offset="6">
                            <textarea v-model="noteText" :rows="7" placeholder="Add a Comment.." />
                          </Mentionable>
                          <div>use @ to mention someone</div>
                          <span class="error-validation">{{ $errorMessage("Note", projectValidation) }}</span>
                        </div>
                        <el-button @click="comment(1)">Comment</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane v-if="[10,3,6].includes(user.Role)">
              <span slot="label"><i class="icon-boxes"></i> Production</span>
             
              <div class="operations form">
                <!-- <el-switch v-model="changePage" v-if="this.project.MainJob" :disabled="this.project.MainJob && project.SubJobs.length > 0" active-text="Create New Production" inactive-text=""> </el-switch> -->
                <template v-if="changePage">
                  <el-table v-if="project.SubJobs" :data="project.SubJobs" style="width: 100%">
                    <el-table-column label="Supplier" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.Supplier != null"> {{ scope.row.Supplier.Title }}</span>
                        <span v-else> No suppliers yet </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="Quantity" label="Order Quantity" min-width="100" > </el-table-column>
                    <el-table-column label="Final Production Quantity" min-width="100" > 
                      <template slot-scope="scope">
                        <span >{{ scope.row.Production.TotalProductionDetails.FinalProductionQuantity }}</span>
                        <!-- <span v-else> No production yet </span> -->
                      </template>
                    </el-table-column>
                    <el-table-column label="Ready To Load" min-width="100" >
                      <template slot-scope="scope">
                        <span >{{ scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                        <!-- <span v-else> No production yet </span> -->
                      </template>
                    </el-table-column>
                    <el-table-column label="Shipped" min-width="300" >
                      <template slot-scope="scope">
                        <div  class="status" :class="getTransferClassStatus(item.Status)" style="float: left;margin-right: 5px;margin-bottom: 5px;" v-for="(item, index) in scope.row.Transfers" :key="item.ID + index">
                          <span >{{ item.Shipment.ProductQuantity}}  {{getEnumsDisplay("TransferStatus", item.Status) }}</span>
                        </div>
                        <span v-if="scope.row.Transfers.length < 1">0</span>
                      </template>
                      <!-- <template slot-scope="scope">
                        <span >{{ scope.row.Operation.TotalProductionDetails.FinalProductionQuantity - scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                        <span v-else> No production yet </span>
                      </template> -->
                    </el-table-column>
                    <!-- <el-table-column label="Remaining Quantity" min-width="170">
                      <template slot-scope="scope">
                        <span v-if="scope.row.Stage == 2 || scope.row.Stage == 3">{{ scope.row.Quantity }}</span>
                        <span v-else> {{ scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                      </template>
                    </el-table-column> -->
                    <el-table-column prop="stage" label="Stage" min-width="100">
                      <template slot-scope="scope">
                        <div class="status primary">
                          <span v-if="getEnums">{{ getEnumsDisplay("JobStage", scope.row.Stage) }}</span>
                        </div>
                      </template>
                    </el-table-column> 
                    <el-table-column prop="status" label="Status" min-width="100">
                      <template slot-scope="scope">
                        <div class="status" :class="getClassStatus(scope.row.Status)">
                          <span v-if="getEnums">{{ getEnumsDisplay("JobStatus", scope.row.Status) }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="title">Create Production<span class="yellow-dot">.</span></div>
                    <el-row :gutter="24">
                      <el-col class="input-container" :sm="8">
                        <div class="text">Quantity</div>
                        <el-input v-model="subQuantity"  class="select"> </el-input>
                        <span class="error-validation">{{ $errorMessage("Quantity", projectValidation) }}</span>
                      </el-col>
                    </el-row>
                    <div class="draft" style="margin-top: 24px">
                      <el-button type="info" class="add-btn" @click="createSubproject()" :loading="loading == 'createSubProject'"><i class="icon-floppy-disk-solid"></i>Create</el-button>
                    </div>
                    <!--  :disabled="!project.DesignInformation.DesignConfirmed && !project.SupplierDesignInformation.DesignConfirmed" -->
                  <div class="line"></div>
                </template>
                <template v-else>
                <!-- <div class="form" v-if="project.Stage == 2 || project.Stage == 3">
                  <div class="title">Design Sample Request</div>
                  <div style="display: flex; justify-content: space-between; align-items: center">
                    <el-checkbox v-model="project.SampleRequest" disabled>Request a design sample.</el-checkbox>
                    <el-input v-model="project.SampleRequestNote" disabled style="width: 50%"></el-input>
                  </div>
                  <div class="line"></div>
                </div> -->
                <div class="title">Supplier Proforma<span class="yellow-dot">.</span></div>

                <div class="upload">
                  <el-upload
                    class="upload-demo"
                    :class="{ disable: project.Production.SupplierProforma.SupplierProformaFile.Url != null ? true : false }"
                    :disabled="project.Production.SupplierProforma.SupplierProformaFile.Url != null ? true : false"
                    drag
                    :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                    :show-file-list="false"
                    :on-success="(response, file, fileList) => handleSupplierProformaSuccess(response, file, fileList)"
                    :headers="{
                      Authorization: $client.defaults.headers.common['Authorization'],
                    }"
                    multiple
                  >
                    <i class="icon-cloudarrow"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 25MB</div> -->
                  </el-upload>
                  <span class="error-validation">{{ $errorMessage("SupplierProformaFile", projectValidation) }}</span>

                  <div class="all-files" v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName">
                    <div class="files">
                      <div class="file">
                        <div class="file-icon">
                          <img :src="getExtension(project.Production.SupplierProforma.SupplierProformaFile.FileName)" />
                        </div>
                        <div class="filecontent">
                          <div class="file-name">{{ project.Production.SupplierProforma.SupplierProformaFile.FileName }}</div>
                          <div class="file-size">{{ fileSize(project.Production.SupplierProforma.SupplierProformaFile.FileSize) }}</div>
                        </div>
                      </div>
                      <div class="icon-status" @click="clearSupplierProforma"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                    </div>
                  </div>
                  <div>
                    <el-col class="supplier-unit-price" :sm="24">
                      <el-col :span="8"> <div class="text">Supplier Unit Price (pcs)</div> </el-col>
                      <el-col :span="16" style="display: flex; align-items: center; gap: 10px">
                        <span style="font-size: 16px">£</span>
                        <el-input v-model="project.SupplierUnitPrice" placeholder="..."></el-input>
                      </el-col>
                    </el-col>
                    <span class="error-validation">{{ $errorMessage("SupplierUnitPrice", projectValidation) }}</span>
                  </div>
                </div>
                <div class="draft" style="margin-top: 24px !important">
                  <el-button type="info" class="add-btn" @click="saveProduction"   :loading="loading == 'supplierProforma'"><i  class="icon-floppy-disk-solid"></i>Confirm</el-button>
                </div>
                <div class="line"></div>
                <div class="title">Product Timeline<span class="yellow-dot">.</span></div>
                <el-col :sm="24" class="right-card">
                  <div class="inline-form">
                    <el-row>
                      <el-col :span="12">
                        <div class="text">Order Date</div>
                      </el-col>
                      <el-col :span="12">
                        <el-date-picker format="dd-MM-yyyy" type="date" v-model="project.Production.ProductTimeline.ProductionStartDate" placeholder="..." prefix-icon="icon-calendarplus"> </el-date-picker>
                        <span class="error-validation">{{ $errorMessage("ProductionStartDate", projectValidation) }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <div class="text">Confirmation Date</div>
                      </el-col>
                      <el-col :span="12">
                        <el-date-picker format="dd-MM-yyyy" type="date" placeholder="..." v-model="project.Production.ProductTimeline.ConfirmationDate" prefix-icon="icon-calendarplus"> </el-date-picker>
                        <span class="error-validation">{{ $errorMessage("ConfirmationDate", projectValidation) }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <div class="text">Confirmation No</div>
                      </el-col>
                      <el-col :span="12">
                        <el-input placeholder="..." v-model="project.Production.ProductTimeline.ConfirmationNo"></el-input>
                        <span class="error-validation">{{ $errorMessage("ConfirmationNo", projectValidation) }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <div class="text">Estimated End Date</div>
                      </el-col>
                      <el-col :span="12">
                        <el-date-picker format="dd-MM-yyyy" type="date" v-model="project.Production.ProductTimeline.EstimatedEndDate" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                        <span class="error-validation">{{ $errorMessage("EstimatedEndDate", projectValidation) }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>

                <div class="draft" style="margin-top: 24px">
                  <el-button type="info" class="add-btn" @click="saveProductionTimeline"  :loading="loading == 'productTimeline'" ><i v-if="loading == false" class="icon-floppy-disk-solid"></i>Confirm</el-button>
                </div>
                <div class="line"></div>
                <el-alert title="Warning!" type="error" v-if="project.Production.ProductIdStickerFile.Url == null && [1].includes(project.JobProductionStatus)" description="Product ID Sticker required to start production." > </el-alert>
                <div class="title">Product ID Sticker<span class="yellow-dot">.</span></div>
                <div class="upload">
                  <el-upload
                    class="upload-demo"
                    :class="{ disable: project.Production.ProductIdStickerFile.Url != null ? true : false }"
                    :disabled="project.Production.ProductIdStickerFile.Url != null ? true : false"
                    drag
                    :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                    :show-file-list="false"
                    :on-success="handleProductIdStickerFileSuccess"
                    :headers="{
                      Authorization: $client.defaults.headers.common['Authorization'],
                    }"
                    multiple
                  >
                    <i class="icon-cloudarrow"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 25MB</div> -->
                  </el-upload>
                  <div class="all-files">
                    <div class="files" v-if="project.Production.ProductIdStickerFile.FileName">
                      <div class="file">
                        <div class="file-icon">
                          <img :src="getExtension(project.Production.ProductIdStickerFile.FileName)" />
                        </div>
                        <div class="filecontent" v-if="project.Production.ProductIdStickerFile">
                          <div class="file-name">{{ project.Production.ProductIdStickerFile.FileName }}</div>
                          <div class="file-size">{{ fileSize(project.Production.ProductIdStickerFile.FileSize) }}</div>
                        </div>
                      </div>
                      <div class="icon-status">
                        <div class="icon-status">
                          <a :href="project.Production.ProductIdStickerFile.Url" target="_blank"><i class="icon-download"></i></a>
                        </div>
                        <div class="icon-status" @click="clearProductIdStickerFile"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                      </div>
                    </div>
                  </div>
                  <div class="generate-buttons">
                    <el-button type="primary" @click="exportBoxLabel" :loading="loading == 'generateBoxLabel'"><i v-if="loading == false" class=""></i>Generate Box Label</el-button>
                  </div>
                  <div class="draft">
                    <el-button type="info" class="add-btn" @click="saveProductIdStickerFile"  :loading="loading == 'productIdSticker'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i>Confirm</el-button>
                  </div>
                  <div class="line"></div>
                </div>
                <el-alert title="Warning!" type="error" v-if="project.Production.LabelFile.Url == null && [1].includes(project.JobProductionStatus)" description="Labels required to start production." > </el-alert>
                <div class="title">Labels<span class="yellow-dot">.</span></div>
                <div class="upload">
                  <el-upload
                    class="upload-demo"
                    :class="{ disable: project.Production.LabelFile.Url != null ? true : false }"
                    :disabled="project.Production.LabelFile.Url != null ? true : false"
                    drag
                    :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                    :show-file-list="false"
                    :on-success="handleLabelSuccess"
                    :headers="{
                      Authorization: $client.defaults.headers.common['Authorization'],
                    }"
                    multiple
                  >
                    <i class="icon-cloudarrow"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <!-- <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div> -->
                  </el-upload>
                  <span class="error-validation">{{ $errorMessage("LabelFile", projectValidation) }}</span>

                  <div class="all-files" v-if="project.Production.LabelFile.FileName">
                    <div class="files">
                      <div class="file">
                        <div class="file-icon">
                          <img :src="getExtension(project.Production.LabelFile.FileName)" />
                        </div>
                        <div class="filecontent">
                          <div class="file-name">{{ project.Production.LabelFile.FileName }}</div>
                          <div class="file-size">{{ fileSize(project.Production.LabelFile.FileSize) }}</div>
                        </div>
                      </div>
                      <div class="icon-status">
                        <div class="icon-status">
                          <a :href="project.Production.LabelFile.Url" target="_blank" download><i class="icon-download"></i></a>
                        </div>
                        <div class="icon-status" @click="clearLabelFile"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="generate-buttons">
                  <el-button type="primary" @click="exportPalletLabel" :loading="loading == 'generatePaletLabel'" ><i v-if="loading == false" class=""></i>Generate Pallet Label</el-button>
                </div>
                <div class="draft">
                  <el-button type="info" class="add-btn" @click="saveProductionLabel"   :loading="loading == 'boxLabel'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i>Confirm</el-button>
                </div>
                <div class="line"></div>

                <div class="title">Production Details<span class="yellow-dot">.</span></div>
                <div class="mini-form" v-if="project.Production">
                  <el-row class="right-card">
                    <div class="inline-form">
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Production End Date</div>
                        </el-col>
                        <el-col :span="12">
                          <el-date-picker format="dd-MM-yyyy" type="date" v-model="project.Production.ProductTimeline.ActualEndDate" placeholder="..." prefix-icon="icon-calendarplus"> </el-date-picker>
                          <span class="error-validation">{{ $errorMessage("ActualEndDate", projectValidation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Order Quantity</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input disabled :value="project.Quantity"> </el-input>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Production Quantity</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.FinalProductionQuantity" placeholder="..."> </el-input>
                          <span v-if="!isTotalProductionValid" class="error-validation">Is not equal to calculated quantity.</span>
                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :span="12">
                          <div class="text">Pallet Quantity</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input v-model="project.Production.TotalProductionDetails.PalletQuantity" placeholder="..."> </el-input>
                          <span class="error-validation">{{ $errorMessage("PalletQuantity", projectValidation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row style="align-items: baseline">
                        <el-col :span="12">
                          <div class="text">Pallet Dimensions (CM)</div>
                        </el-col>
                        <el-col :span="12" class="dimensions-active">
                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.PalletDimensions.X" placeholder="...">
                              <div slot="append">W</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("PalletDimensions.X", projectValidation) }}</span>
                          </el-col>

                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.PalletDimensions.Y" placeholder="...">
                              <div slot="append">L</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("PalletDimensions.Y", projectValidation) }}</span>
                          </el-col>

                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.PalletDimensions.Z" placeholder="...">
                              <div slot="append">H</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("PalletDimensions.Z", projectValidation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Box Quantity</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.BoxQuantity" placeholder="..."> </el-input>
                          <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Pieces in Box</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.Box.PiecesInBox" placeholder="..."> </el-input>
                          <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                        </el-col>
                      </el-row>
                        <div class="inline-form" v-for="(item,index) in project.Production.TotalProductionDetails.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                          <el-row>
                            <el-col :span="12">
                              <el-col :span="8" >
                                <div class="icon">
                                  <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                </div>
                              </el-col>
                              <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.OddBoxes[index].BoxQuantity"> </el-input>
                              <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-col :span="8" >
                                <div class="icon">
                                  <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                </div>
                              </el-col>
                              <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.OddBoxes[index].PiecesInBox"> </el-input>
                                <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                            </el-col>
                          </el-row>
                        </div>
                      <el-row>
                        <el-col :span="20">

                        </el-col>
                        <el-col :span="4">
                          <el-button type="info" class="add-btn" @click="addOddBox"  ><i class="icon-plus-solid"></i>Add Odd Box</el-button>
                        </el-col>
                      </el-row>
                      <el-row>
                        <!-- <el-col :span="12">
                          <div class="text">Pieces in Odd Box</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input v-model="project.Production.TotalProductionDetails.Box.PiecesInOddBox"  disabled> </el-input>
                          <span class="error-validation" v-if="!isValidOddBoxQuantity"> Odd box quantity can not be equal or more than box quantity. </span>
                        </el-col> -->
                      </el-row>
                      <el-row style="align-items: baseline">
                        <el-col :span="12">
                          <div class="text">Box Dimensions (CM)</div>
                        </el-col>
                        <el-col :span="12" class="dimensions-active">
                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.Box.BoxDimensions.X" placeholder="...">
                              <div slot="append">W</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.X", projectValidation) }}</span>
                          </el-col>

                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.Box.BoxDimensions.Y" placeholder="...">
                              <div slot="append">L</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Y", projectValidation) }}</span>
                          </el-col>

                          <el-col :span="24">
                            <el-input v-model="project.Production.TotalProductionDetails.Box.BoxDimensions.Z" placeholder="...">
                              <div slot="append">H</div>
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Z", projectValidation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="12">
                          <div class="text">Weight (kg)</div>
                        </el-col>
                        <el-col :span="12">
                          <el-input v-model="project.Production.TotalProductionDetails.Box.Weight" placeholder="..."> </el-input>
                          <span class="error-validation">{{ $errorMessage("Box.Weight", projectValidation) }}</span>
                        </el-col>
                      </el-row>
                    </div>
                  </el-row>
                  <div class="draft">
                    <el-button type="info" class="add-btn" @click="saveTotalProductionDetails" :disabled="!isTotalProductionValid"  :loading="loading == 'totalProductionDetails'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i>Confirm</el-button>
                  </div>
                  <div class="line"></div>
                </div>

                <div class="draft" style="margin-top: 24px">
                  <el-button type="primary" class="add-btn" @click="completedProduction" :disabled="[3].includes(project.JobProductionStatus)" ><i class="icon-check-double-solid"></i>Complete Production</el-button>
                </div>
                <div class="line"></div>
                <div class="comment-body">
                  <div class="comments form">
                    <div class="title">Comments<span class="yellow-dot">.</span></div>
                    <div class="comment-container" v-for="comment in project.Notes.filter((x) => x.Type == 2)" :key="comment.ID">
                      <div class="line"></div>
                      <div class="content">
                        <div class="comment-top">
                          <div class="left">
                            <div class="circle">{{ comment.User.FirstName[0] + comment.User.LastName[0] }}</div>
                            <div class="personel-infos">
                              <div class="user-name">{{ comment.User.FirstName + " " + comment.User.LastName }}</div>
                              <div class="status">
                                <span>{{ getEnumsDisplay("TypeOfAuthority", comment.User.Role) }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                        <div class="comment-content">{{ comment.Content }}</div>
                        <div class="comment-date">{{ $moment(comment.CreatedDate).format("DD-MM-YYYY") }}</div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="comment-container">
                      <div class="comment-text">
                        <div class="left">
                          <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                        </div>
                        <div class="textarea-group">
                          <Mentionable :keys="['@']" :items="items" @apply="onApply(...arguments)" offset="6">
                            <textarea v-model="noteText" :rows="7" placeholder="Add a Comment.." />
                          </Mentionable>
                          <div>use @ to mention someone</div>
                          <span class="error-validation">{{ $errorMessage("Note", projectValidation) }}</span>
                        </div>
                        <el-button @click="comment(2)">Comment</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              </div>
            </el-tab-pane>
            <!-- -------------------------------------------------------------------------------------------------------------- OPERATIONS TAB -------------------------------------------------------------------------------------------------------------- -->
            <el-tab-pane v-if="[10,3,6].includes(user.Role) && !project.MainJob">
              <span slot="label"><i class="icon-cartflatbed"></i> Operations</span>
              <div class="operations form">
                <div class="title">Operations<span class="yellow-dot">.</span></div>

                <el-col :sm="24" class="right-card">
                  <div class="inline-form">
                    <el-row>
                      <el-col :span="12">
                        <div class="text"><i class="icon-calendarday"></i>Production End Date</div>
                      </el-col>
                      <el-col :span="12">
                        <el-date-picker format="dd-MM-yyyy" type="date" v-model="project.ActualEndDate" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-collapse accordion>
                  <el-collapse-item>
                    <template slot="title">
                      <div class="row-title">
                        <div class="title">Production Details<span class="yellow-dot">.</span></div>
                      </div>
                    </template>
                    <div class="mini-form">
                      <el-row class="right-card">
                        <div class="inline-form">
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Order Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input disabled :value="project.Quantity"> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Production Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.TotalProductionDetails.FinalProductionQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Pallet Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.TotalProductionDetails.PalletQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row style="align-items: baseline">
                            <el-col :span="12">
                              <div class="text">Pallet Dimensions (CM)</div>
                            </el-col>
                            <el-col :span="12" class="dimensions-deactive">
                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.PalletDimensions.X" placeholder="..." disabled>
                                  <div slot="append">W</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.PalletDimensions.Y" placeholder="..." disabled>
                                  <div slot="append">L</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.PalletDimensions.Z" placeholder="..." disabled>
                                  <div slot="append">H</div>
                                </el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Box Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.TotalProductionDetails.BoxQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Pieces in Box</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.TotalProductionDetails.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <div class="inline-form" v-for="(item,index) in project.Production.TotalProductionDetails.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                            <el-row>
                              <el-col :span="12">
                                <!-- <el-col :span="8" >
                                  <div class="icon">
                                    <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                  </div>
                                </el-col> -->
                                <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.OddBoxes[index].BoxQuantity" disabled> </el-input>
                                <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <!-- <el-col :span="8" >
                                  <div class="icon">
                                    <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                  </div>
                                </el-col> -->
                                <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input @change="checkTotalProduction" v-model="project.Production.TotalProductionDetails.OddBoxes[index].PiecesInBox" disabled> </el-input>
                                  <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                          </div>
                        <!-- <el-row>
                          <el-col :span="20">

                          </el-col>
                          <el-col :span="4">
                            <el-button type="info" class="add-btn" @click="addOddBox"  ><i class="icon-plus-solid"></i>Add Odd Box</el-button>
                          </el-col>
                        </el-row> -->
                          <el-row style="align-items: baseline">
                            <el-col :span="12">
                              <div class="text">Box Dimensions (CM)</div>
                            </el-col>
                            <el-col :span="12" class="dimensions-deactive">
                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.Box.BoxDimensions.X" placeholder="..." disabled>
                                  <div slot="append">W</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.Box.BoxDimensions.Y" placeholder="..." disabled>
                                  <div slot="append">L</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.TotalProductionDetails.Box.BoxDimensions.Z" placeholder="..." disabled>
                                  <div slot="append">H</div>
                                </el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Weight (kg)</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.TotalProductionDetails.Box.Weight" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                        </div>
                      </el-row>
                    </div>
                  </el-collapse-item>
                </el-collapse>

                <el-collapse v-if="shipmentForm != null || (project.Operation.Shipments && project.Operation.Shipments.length > 0)">
                  <template v-if="project.Operation.Shipments && project.Operation.Shipments.length > 0">
                    <el-collapse-item v-for="(shipment, index) in project.Operation.Shipments" :key="index + 'operation'">
                      <template slot="title">
                        <div class="row-title">
                          <div class="title">Shipment {{ index + 1 }}<span class="yellow-dot">.</span> -Shipment Collection Date: {{ $moment(shipment.CollectionDate).format("DD-MM-YYYY") }}</div>
                          <div class="icon"></div>
                        </div>
                      </template>
                      <div class="design mini-form">
                        <div class="mini-form">
                          <el-col :sm="24" class="right-card">
                            <div class="inline-form">
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Customs</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-date-picker format="dd-MM-yyyy" type="date" disabled v-model="shipment.EstimatedArrivalDateCustoms" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Warehouse</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-date-picker format="dd-MM-yyyy" type="date" disabled v-model="shipment.EstimatedArrivalDateWarehouse" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                                </el-col>
                              </el-row>

                              <el-row>
                                <el-col :span="12">
                                  <div class="text">
                                    <i class="icon-destination-warehouse"></i>
                                    <div>Destination Warehouse</div>
                                  </div>
                                </el-col>
                                <el-col :span="12">
                                  <el-select v-model="shipment.WarehouseId" placeholder="Select" disabled>
                                    <el-option v-for="(item, index) in getWarehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">
                                    <i class="icon-agent"></i>
                                    <div>Agent</div>
                                  </div>
                                </el-col>
                                <el-col :span="12">
                                  <el-select v-model="shipment.AgentId" placeholder="Select" disabled>
                                    <el-option v-for="item in agentList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-truck"></i>Vehicle Details</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input v-model="shipment.VehicleDetails" placeholder="..." disabled> </el-input>
                                </el-col>
                              </el-row>
                            </div>
                          </el-col>
                        </div>
                        <div class="line"></div>
                        <el-row>
                          <div class="title">Shipping Details<span class="yellow-dot">.</span></div>
                          <div class="form">
                            <el-row class="right-card">
                              <div class="inline-form">
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Product Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.ProductQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Pallet Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.PalletQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row style="align-items: baseline">
                                  <el-col :span="12">
                                    <div class="text">Pallet Dimensions (CM)</div>
                                  </el-col>
                                  <el-col :span="12" class="dimensions-deactive">
                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.X" placeholder="..." disabled>
                                        <div slot="append">W</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.Y" placeholder="..." disabled>
                                        <div slot="append">L</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.Z" placeholder="..." disabled>
                                        <div slot="append">H</div>
                                      </el-input>
                                    </el-col>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Box Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.BoxQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Pieces in Box</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <div class="inline-form" v-for="(item,index) in shipment.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                                  <el-row>
                                    <el-col :span="12">
                                      <!-- <el-col :span="8" >
                                        <div class="icon">
                                          <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                        </div>
                                      </el-col> -->
                                      <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                                    </el-col>
                                    <el-col :span="12">
                                      <el-input @change="checkTotalProduction" v-model="shipment.OddBoxes[index].BoxQuantity" disabled> </el-input>
                                      <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                    <el-col :span="12">
                                      <!-- <el-col :span="8" >
                                        <div class="icon">
                                          <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                        </div>
                                      </el-col> -->
                                      <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                                    </el-col>
                                    <el-col :span="12">
                                      <el-input @change="checkTotalProduction" v-model="shipment.OddBoxes[index].PiecesInBox" disabled> </el-input>
                                        <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                                    </el-col>
                                  </el-row>
                                </div>
                                <el-row style="align-items: baseline">
                                  <el-col :span="12">
                                    <div class="text">Box Dimensions (CM)</div>
                                  </el-col>
                                  <el-col :span="12" class="dimensions-deactive">
                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.X" placeholder="..." disabled>
                                        <div slot="append">W</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.Y" placeholder="..." disabled>
                                        <div slot="append">L</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.Z" placeholder="..." disabled>
                                        <div slot="append">H</div>
                                      </el-input>
                                    </el-col>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Weight (kg)</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.Box.Weight" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                              </div>
                            </el-row>
                          </div>
                        </el-row>
                        <div class="line"></div>

                        <el-row>
                          <div class="title2">Packing List</div>
                          <div class="upload">
                            <div class="all-files" v-if="shipment.PackingList.FileName">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(shipment.PackingList.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ shipment.PackingList.FileName }}</div>
                                    <div class="file-size">{{ fileSize(shipment.PackingList.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <i class="icon-check"></i><a :href="shipment.PackingList.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-row>
                        <div class="line"></div>

                        <el-row>
                          <div class="title2">Supplier Invoice</div>

                          <div class="upload">
                            <div class="all-files" v-if="shipment.SupplierProforma.FileName">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(shipment.SupplierProforma.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ shipment.SupplierProforma.FileName }}</div>
                                    <div class="file-size">{{ fileSize(shipment.SupplierProforma.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <i class="icon-check"></i><a :href="shipment.SupplierProforma.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-row>
                      </div>
                    </el-collapse-item>
                  </template>

                  <el-collapse-item>
                    <template slot="title">
                      <div class="row-title">
                        <div class="title">Remaining Quantity<span class="yellow-dot">.</span></div>
                      </div>
                    </template>
                    <div class="mini-form">
                      <el-row class="right-card">
                        <div class="inline-form">
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Production Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input disabled :value="project.Operation.TotalProductionDetails.FinalProductionQuantity"> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Remaining Product Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.RemainingQuantity.FinalProductionQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Pallet Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.RemainingQuantity.PalletQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row style="align-items: baseline" v-if="project.Operation.RemainingQuantity.PalletDimensions">
                            <el-col :span="12">
                              <div class="text">Pallet Dimensions (CM)</div>
                            </el-col>
                            <el-col :span="12" class="dimensions-deactive">
                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.PalletDimensions.X" placeholder="..." disabled>
                                  <div slot="append">W</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.PalletDimensions.Y" placeholder="..." disabled>
                                  <div slot="append">L</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.PalletDimensions.Z" placeholder="..." disabled>
                                  <div slot="append">H</div>
                                </el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Box Quantity</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.RemainingQuantity.BoxQuantity" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Pieces in Box</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.RemainingQuantity.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                          <div class="inline-form" v-for="(item,index) in project.Operation.RemainingQuantity.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                            <el-row>
                              <el-col :span="12">
                                <!-- <el-col :span="8" >
                                  <div class="icon">
                                    <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                  </div>
                                </el-col> -->
                                <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input @change="checkTotalProduction" v-model="project.Operation.RemainingQuantity.OddBoxes[index].BoxQuantity" disabled> </el-input>
                                <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <!-- <el-col :span="8" >
                                  <div class="icon">
                                    <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                                  </div>
                                </el-col> -->
                                <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input @change="checkTotalProduction" v-model="project.Operation.RemainingQuantity.OddBoxes[index].PiecesInBox" disabled> </el-input>
                                  <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                          </div>
                          <el-row style="align-items: baseline" v-if="project.Operation.RemainingQuantity.Box.BoxDimensions">
                            <el-col :span="12">
                              <div class="text">Box Dimensions (CM)</div>
                            </el-col>
                            <el-col :span="12" class="dimensions-deactive">
                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.Box.BoxDimensions.X" placeholder="..." disabled>
                                  <div slot="append">W</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.Box.BoxDimensions.Y" placeholder="..." disabled>
                                  <div slot="append">L</div>
                                </el-input>
                              </el-col>

                              <el-col :span="24">
                                <el-input v-model="project.Operation.RemainingQuantity.Box.BoxDimensions.Z" placeholder="..." disabled>
                                  <div slot="append">H</div>
                                </el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <div class="text">Weight (kg)</div>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="project.Operation.RemainingQuantity.Box.Weight" placeholder="..." disabled> </el-input>
                            </el-col>
                          </el-row>
                        </div>
                      </el-row>
                    </div>
                  </el-collapse-item>

                  <el-collapse-item v-if="shipmentForm != null">
                    <template slot="title">
                      <div class="row-title">
                        <div class="title">New Shipment<span class="yellow-dot">.</span></div>
                        <div class="icon">
                          <div class="icon-row-trash" @click="shipmentForm = null"></div>
                        </div>
                      </div>
                    </template>
                    <div class="design mini-form">
                      <div class="mini-form">
                        <el-col :sm="24" class="right-card">
                          <div class="inline-form">
                            <el-row>
                              <el-col :span="12">
                                <div class="text"><i class="icon-calendarday"></i>Collection Date</div>
                              </el-col>
                              <el-col :span="12">
                                <el-date-picker format="dd-MM-yyyy" type="date" v-model="shipmentForm.CollectionDate" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                                <span class="error-validation">{{ $errorMessage("CollectionDate", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Customs</div>
                              </el-col>
                              <el-col :span="12">
                                <el-date-picker format="dd-MM-yyyy" type="date" v-model="shipmentForm.EstimatedArrivalDateCustoms" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                                <span class="error-validation">{{ $errorMessage("EstimatedArrivalDateCustoms", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Warehouse</div>
                              </el-col>
                              <el-col :span="12">
                                <el-date-picker format="dd-MM-yyyy" type="date" v-model="shipmentForm.EstimatedArrivalDateWarehouse" placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                                <span class="error-validation">{{ $errorMessage("EstimatedArrivalDateWarehouse", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text">
                                  <i class="icon-destination-warehouse"></i>
                                  <div>Destination Warehouse</div>
                                </div>
                              </el-col>
                              <el-col :span="12">
                                <el-select v-model="shipmentForm.WarehouseId" placeholder="Select">
                                  <el-option v-for="(item, index) in getWarehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                                </el-select>
                                <span class="error-validation">{{ $errorMessage("WarehouseId", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text">
                                  <i class="icon-agent"></i>
                                  <div>Agent</div>
                                </div>
                              </el-col>
                              <el-col :span="12">
                                <el-select v-model="shipmentForm.AgentId" placeholder="Select" :clearable="true">
                                  <el-option v-for="(item, index) in agentList.filter((x) => x.IsDeactivated != true)" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                                </el-select>
                                <!-- <span class="error-validation">{{ $errorMessage("AgentId", projectValidation) }}</span> -->
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text"><i class="icon-truck"></i>Vehicle Details</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input class="vehicle-input" v-model="shipmentForm.VehicleDetails" placeholder="..." :clearable="true"> </el-input>
                                <!-- <span class="error-validation">{{ $errorMessage("VehicleDetails", projectValidation) }}</span> -->
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                      </div>
                      <div class="line"></div>
                      <el-row>
                        <div class="title">Shipping Details<span class="yellow-dot">.</span></div>
                        <div class="form">
                          <el-row class="right-card">
                            <div class="inline-form">
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Production Quantity</div>
                                </el-col>
                                <el-col :span="12">
                                <el-input disabled :value="project.Operation.TotalProductionDetails.FinalProductionQuantity"> </el-input>
                              </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Shipment Product Quantity</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input @change="checkShippmentQuantity" v-model="shipmentForm.ShipmentProductQuantity" placeholder="..."> </el-input>
                                  <span v-if="!isShippmentQuantityValid" class="error-validation">Is not equal to calculated quantity.</span>
                                </el-col>
                              </el-row>

                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Pallet Quantity</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input v-model="shipmentForm.PalletQuantity" placeholder="..."> </el-input>
                                  <span class="error-validation">{{ $errorMessage("PalletQuantity", projectValidation) }}</span>
                                </el-col>
                              </el-row>
                              <el-row style="align-items: baseline">
                                <el-col :span="12">
                                  <div class="text">Pallet Dimensions (CM)</div>
                                </el-col>
                                <el-col :span="12" class="dimensions-active">
                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.PalletDimensions.X" placeholder="...">
                                      <div slot="append">W</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("PalletDimensions.X", projectValidation) }}</span>
                                  </el-col>

                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.PalletDimensions.Y" placeholder="...">
                                      <div slot="append">L</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("PalletDimensions.Y", projectValidation) }}</span>
                                  </el-col>

                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.PalletDimensions.Z" placeholder="...">
                                      <div slot="append">H</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("PalletDimensions.Z", projectValidation) }}</span>
                                  </el-col>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Box Quantity</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input @change="checkShippmentQuantity" v-model="shipmentForm.BoxQuantity" placeholder="..." disabled> </el-input>
                                  <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Pieces in Box</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input @change="checkShippmentQuantity" v-model="shipmentForm.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                                  <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                                </el-col>
                              </el-row>
                              <div class="inline-form" v-for="(item,index) in shipmentForm.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                                <el-row>
                                  <el-col :span="12">
                                    <el-col :span="8" >
                                      <div class="icon">
                                        <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteShipmentOddBox(index)"></div>
                                      </div>
                                    </el-col>
                                    <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input @change="checkShippmentQuantity" v-model="shipmentForm.OddBoxes[index].BoxQuantity" disabled> </el-input>
                                    <span class="error-validation">{{ $errorMessage("BoxQuantity", projectValidation) }}</span>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <el-col :span="8" >
                                      <div class="icon">
                                        <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteShipmentOddBox(index)"></div>
                                      </div>
                                    </el-col>
                                    <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input @change="checkShippmentQuantity" v-model="shipmentForm.OddBoxes[index].PiecesInBox" disabled> </el-input>
                                      <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", projectValidation) }}</span>
                                  </el-col>
                                </el-row>
                              </div>
                              <el-row>
                                <el-col :span="20">
                                </el-col>
                                <el-col :span="4">
                                  <el-button type="info" class="add-btn" @click="addShipmentOddBox" ><i class="icon-arrows-rotate-solid"></i>Add Odd Boxes</el-button>
                                </el-col>
                              </el-row> 
                              <el-row style="align-items: baseline">
                                <el-col :span="12">
                                  <div class="text">Box Dimensions (CM)</div>
                                </el-col>
                                <el-col :span="12" class="dimensions-active">
                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.Box.BoxDimensions.X" placeholder="...">
                                      <div slot="append">W</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.X", projectValidation) }}</span>
                                  </el-col>

                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.Box.BoxDimensions.Y" placeholder="...">
                                      <div slot="append">L</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Y", projectValidation) }}</span>
                                  </el-col>

                                  <el-col :span="24">
                                    <el-input v-model="shipmentForm.Box.BoxDimensions.Z" placeholder="...">
                                      <div slot="append">H</div>
                                    </el-input>
                                    <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Z", projectValidation) }}</span>
                                  </el-col>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">Weight (kg)</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input v-model="shipmentForm.Box.Weight" placeholder="..."> </el-input>
                                  <span class="error-validation">{{ $errorMessage("Box.Weight", projectValidation) }}</span>
                                </el-col>
                              </el-row>
                            </div>
                          </el-row>
                        </div>
                      </el-row>
                      <div class="line"></div>

                      <el-row>
                        <div class="title2">Packing List</div>
                        <div class="upload">
                          <el-upload
                            class="upload-demo"
                            :class="{ disable: shipmentForm.PackingList.Url != null ? true : false }"
                            :disabled="shipmentForm.PackingList.Url != null ? true : false"
                            drag
                            :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                            :show-file-list="false"
                            :on-success="(response, file, fileList) => handlePackingListFileSuccess(response, file, fileList)"
                            :headers="{
                              Authorization: $client.defaults.headers.common['Authorization'],
                            }"
                          >
                            <i class="icon-cloudarrow"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            <!-- <div class="el-upload__tip" slot="tip">Excel or Word files with a size less than 5mb</div> -->
                          </el-upload>
                          <span class="error-validation">{{ $errorMessage("PackingList", projectValidation) }}</span>
                          <div class="all-files" v-if="shipmentForm.PackingList.FileName">
                            <div class="files">
                              <div class="file">
                                <div class="file-icon">
                                  <img :src="getExtension(shipmentForm.PackingList.FileName)" />
                                </div>
                                <div class="filecontent">
                                  <div class="file-name">{{ shipmentForm.PackingList.FileName }}</div>
                                  <div class="file-size">{{ fileSize(shipmentForm.PackingList.FileSize) }}</div>
                                </div>
                              </div>
                              <div class="icon-status" @click="clearPackingListFile"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                            </div>
                          </div>
                        </div>
                      </el-row>
                      <div class="line"></div>
                      <el-row>
                        <div class="title2">Supplier Invoice</div>
                        <div class="upload">
                          <el-upload
                            class="upload-demo"
                            :class="{ disable: shipmentForm.SupplierProforma.Url != null ? true : false }"
                            :disabled="shipmentForm.SupplierProforma.Url != null ? true : false"
                            drag
                            :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                            :show-file-list="false"
                            :on-success="(response, file, fileList) => handleSupplierProformaFileSuccess(response, file, fileList)"
                            :headers="{
                              Authorization: $client.defaults.headers.common['Authorization'],
                            }"
                            multiple
                          >
                            <i class="icon-cloudarrow"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 25MB</div> -->
                          </el-upload>
                          <span class="error-validation">{{ $errorMessage("SupplierProforma", projectValidation) }}</span>
                          <div class="all-files" v-if="shipmentForm.SupplierProforma.FileName">
                            <div class="files">
                              <div class="file">
                                <div class="file-icon">
                                  <img :src="getExtension(shipmentForm.SupplierProforma.FileName)" />
                                </div>
                                <div class="filecontent">
                                  <div class="file-name">{{ shipmentForm.SupplierProforma.FileName }}</div>
                                  <div class="file-size">{{ fileSize(shipmentForm.SupplierProforma.FileSize) }}</div>
                                </div>
                              </div>
                              <div class="icon-status" @click="clearSupplierProformaFile"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                            </div>
                          </div>
                        </div>
                      </el-row>
                      <div class="line"></div>
                      <div class="draft">
                        <el-button type="info" class="add-btn" @click="saveShipment" :disabled="!isShippmentQuantityValid"><i class="icon-floppy-disk-solid"></i>Save Shipment</el-button>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div class="draft shipment-btn">
                  <el-button type="info" class="add-btn" @click="createNewShipment" :disabled="disableCreateShippment"><i class="icon-plus-solid"></i>Create New Shipment</el-button>
                </div>

                <div class="line"></div>
                <div class="footerbutton">
                  <el-button type="primary" class="add-btn" @click="completedOperation" ><i class="icon-check-double-solid"></i> Complete Operation</el-button>
                </div>
                <div class="line"></div>
                <div class="comment-body">
                  <div class="comments form">
                    <div class="title">Comments<span class="yellow-dot">.</span></div>
                    <div class="comment-container" v-for="comment in project.Notes.filter((x) => x.Type == 3)" :key="comment.ID">
                      <div class="line"></div>
                      <div class="content">
                        <div class="comment-top">
                          <div class="left">
                            <div class="circle">{{ comment.User.FirstName[0] + comment.User.LastName[0] }}</div>
                            <div class="personel-infos">
                              <div class="user-name">{{ comment.User.FirstName + " " + comment.User.LastName }}</div>
                              <div class="status">
                                <span>{{ getEnumsDisplay("TypeOfAuthority", comment.User.Role) }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                        <div class="comment-content">{{ comment.Content }}</div>
                        <div class="comment-date">{{ $moment(comment.CreatedDate).format("DD-MM-YYYY") }}</div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="comment-container">
                      <div class="comment-text">
                        <div class="left">
                          <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                        </div>
                        <div class="textarea-group">
                          <Mentionable :keys="['@']" :items="items" @apply="onApply(...arguments)" offset="6">
                            <textarea v-model="noteText" :rows="7" placeholder="Add a Comment.." />
                          </Mentionable>
                          <div>use @ to mention someone</div>
                          <span class="error-validation">{{ $errorMessage("Note", projectValidation) }}</span>
                        </div>
                        <el-button @click="comment(3)">Comment</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="[10,5].includes(user.Role)">
              <span slot="label"><i class="icon-file-invoice-dollar"></i> Accounts</span>
              <div class="accounts form">
                <!-- <div class="title">Quotation<span class="yellow-dot">.</span></div>
                <el-table v-if="quotation" :data="quotationList" style="width: 100%">
                  <el-table-column prop="OfferNo" label="Offer No" width="180"> </el-table-column>
                  <el-table-column prop="Customer.CompanyName" label="Customer"></el-table-column>
                  <el-table-column prop="date" label="Date">
                    <template slot-scope="scope">
                      <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
                      <br />
                      <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="Status">
                    <template slot-scope="scope">
                      <div class="status" :class="getClassOfferStatus(scope.row.Status)">
                        <span v-if="getEnums">{{ getEnumsDisplay("OfferStatus", scope.row.Status) }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Account Manager">
                    <template slot-scope="scope"> {{ scope.row.AccountManager.FullName }} </template>
                  </el-table-column>
                </el-table>
                <div v-if="!quotation" class="no-data">No Data</div> -->

                <div class="title">Proforma<span class="yellow-dot">.</span></div>
                <div class="view-info">
                  <el-table v-if="proforma" :data="proformaList">
                    <el-table-column prop="ProformaNo" label="Proforma No" width="180"> </el-table-column>
                    <el-table-column label="Company Name">
                      <template slot-scope="scope">
                        <div class="customer">{{ scope.row.Customer.CompanyName }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="date" label="Date">
                      <template slot-scope="scope">
                        <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
                        ><br />
                        <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status">
                      <template slot-scope="scope">
                        <div class="status" :class="getClassProformaStatus(scope.row.Status)">
                          <span v-if="getEnums">{{ getEnumsDisplay("ProformaStatus", scope.row.Status) }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column prop="Total" label="Total"> </el-table-column> -->
                    <el-table-column label="Preview" width="160">
                      <template slot-scope="scope">
                        <el-button class="popover-eye" @click="seePrewiev(scope.row.ID,proforma)" slot="reference">
                          <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.ID"></i>
                          <i class="icon-eye-popover" v-else></i>
                        </el-button>
                        <el-button class="popover-eye" slot="reference"> </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="view-info">
                  <el-table v-if="!proforma && project.ProformaNo==null" :data="withoutProformaData">
                    <el-table-column prop="UnitPrice"  label="Unit Price" > </el-table-column>
                    <el-table-column prop="Quantity" label="Quantity"> </el-table-column>
                  </el-table>
                </div>
                <div v-if="!proforma && project.ProformaNo!=null">No Data</div>
                <el-collapse accordion v-if="!project.MainJob">
                  <el-collapse-item>
                    <template slot="title">Supplier Proforma<span class="yellow-dot">.</span></template>
                    <div class="documents">
                      <div class="document">
                        <div class="document-informations">
                          <div class="document-info" v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName && project.Production.SupplierProforma.IsConfirmed == true">
                            <div class="left">
                              <div class="text">Confirmed Date:</div>
                            </div>
                            <template>
                              <div class="right">{{ $moment(project.Production.SupplierProforma.ConfirmedDate).format("DD-MM-YYYY" + " " + "hh-mm") }}</div>
                            </template>
                          </div>
                          <div class="document-info" v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName && project.Production.SupplierProforma.IsConfirmed == true">
                            <div class="left">
                              <div class="text">Accounting:</div>
                            </div>
                            <template>
                              <div class="right" v-if="project.Production.SupplierProforma.ConfirmedUser">{{ project.Production.SupplierProforma.ConfirmedUser.FullName }}</div>
                            </template>
                          </div>
                          <div class="document-info">
                            <div class="left">
                              <div class="text">{{ project.Production.SupplierProforma.SupplierProformaFile.FileName != null ? "Uploaded Files:" : "No Data" }}</div>
                            </div>
                            <div class="right"></div>
                          </div>

                          <div class="upload">
                            <div class="all-files" v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(project.Production.SupplierProforma.SupplierProformaFile.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ project.Production.SupplierProforma.SupplierProformaFile.FileName }}</div>
                                    <div class="file-size">{{ fileSize(project.Production.SupplierProforma.SupplierProformaFile.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <i class="icon-check"></i><a :href="project.Production.SupplierProforma.SupplierProformaFile.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <el-col class="supplier-unit-price" :sm="24" v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName">
                          <el-col :span="8"> <div class="text">Supplier Unit Price (pcs)</div> </el-col>
                          <el-col :span="16" style="display: flex; align-items: center; gap: 10px">
                            <span style="font-size: 16px">£</span>
                            <el-input v-model="project.SupplierUnitPrice" disabled></el-input>
                          </el-col>
                        </el-col>
                      </div>
                    </div>
                    <!-- <div class="draft" style="margin-top: 24px !important">
                      <el-button v-if="project.Production.SupplierProforma.SupplierProformaFile.FileName" :disabled="project.Production.SupplierProforma.IsConfirmed" type="primary" class="add-btn" @click="confirmSupplierProforma"><i class="icon-check-double-solid"></i>Confirm Supplier Proforma</el-button>
                    </div> -->
                  </el-collapse-item>
                </el-collapse>

                <el-collapse accordion v-if="project.MainJob">
                  <el-collapse-item>
                    <template slot="title">Customer Invoice Informations<span class="yellow-dot">.</span></template>
                    <div class="design mini-form">
                      <div class="mini-form">
                        <el-col :sm="24" class="right-card">
                          <div class="inline-form">
                            <el-row>
                              <el-col :span="12">
                                <div class="text"><i class="icon-calendarday"></i>Customer Invoice Date</div>
                              </el-col>
                              <el-col :span="12">
                                <el-date-picker format="dd-MM-yyyy" type="date" v-model="project.CustomerInvoiceInfos.CustomerInvoiceDate" placeholder="..." prefix-icon="icon-calendarplus" ></el-date-picker>
                              </el-col> 
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text">Customer Invoice Number</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input placeholder="..." v-model="project.CustomerInvoiceInfos.CustomerInvoiceNumber"></el-input>
                                <span class="error-validation">{{ $errorMessage("ConfirmationNo", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <div class="text">Customer Invoice Quantity</div>
                              </el-col>
                              <el-col :span="12">
                                <el-input placeholder="..." v-model="project.CustomerInvoiceInfos.CustomerInvoiceQuantity"></el-input>
                                <span class="error-validation">{{ $errorMessage("ConfirmationNo", projectValidation) }}</span>
                              </el-col>
                            </el-row>
                            <div class="line"></div>
                            <div class="draft">
                              <el-button type="info" class="add-btn" @click="saveCustomerInvoiceInfos" :disabled="project.CustomerInvoiceInfos.Isconfirm" :loading="loading == 'saveCustomerInvoice'"><i class="icon-floppy-disk-solid"></i>Confirm</el-button>
                            </div>
                          </div>
                        </el-col>
                      </div> 
                    </div> 
                  </el-collapse-item>
                </el-collapse>

                <el-collapse accordion v-if="project.MainJob">
                  <el-collapse-item>
                    <template slot="title">
                      <div class="row-title">
                        <div class="title">Unit Price<span class="yellow-dot">.</span></div>
                      </div>
                    </template>
                    <div class="mini-form">
                      <div class="view-info">
                        <template>
                          <el-table v-if="project.Product.UnitPriceHistories" :data="UnitPriceHistoriesSort">
                            <el-table-column prop="Authorized" label="Authorized"> </el-table-column>
                            <el-table-column label="Update Date">
                              <template slot-scope="scope">
                                <span>{{ $moment(scope.row.UpdatedDate).format("DD-MM-YYYY") }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="Unit Price">
                              <template slot-scope="scope"> £ {{ scope.row.UnitPrice.toLocaleString("en", { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 3 }) }} </template>
                            </el-table-column>
                            <el-table-column label="Supplier Unit Price">
                              <template v-if="scope.row.SupplierUnitPrice != null" slot-scope="scope"> £ {{ scope.row.SupplierUnitPrice.toLocaleString("en", { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 3 }) }} </template>
                            </el-table-column>
                          </el-table>
                        </template>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>

                <!-- ACCOUNTS UNIT PRICE ALTINDAKI SHIPMENT YERİ -->
                <el-collapse accordion v-if="project.Operation.Shipments && project.Operation.Shipments.length > 0 && !project.MainJob">
                  <template v-if="project.Operation.Shipments && project.Operation.Shipments.length > 0">
                    <el-collapse-item v-for="(shipment, index) in project.Operation.Shipments" :key="index + 'account'">
                      <template slot="title">
                        <div class="row-title">
                          <div class="title">Shipment {{ index + 1 }}<span class="yellow-dot">.</span> -Shipment Collection Date: {{ $moment(shipment.CollectionDate).format("DD-MM-YYYY") }}</div>
                          <div class="icon"></div>
                        </div>
                      </template>
                      <div class="design mini-form">
                        <div class="mini-form">
                          <el-col :sm="24" class="right-card">
                            <div class="inline-form">
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Customs</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-date-picker format="dd-MM-yyyy" type="date" v-model="shipment.EstimatedArrivalDateCustoms" placeholder="..." prefix-icon="icon-calendarplus" disabled></el-date-picker>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Warehouse</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-date-picker format="dd-MM-yyyy" type="date" v-model="shipment.EstimatedArrivalDateWarehouse" placeholder="..." prefix-icon="icon-calendarplus" disabled></el-date-picker>
                                </el-col>
                              </el-row>

                              <el-row>
                                <el-col :span="12">
                                  <div class="text">
                                    <i class="icon-destination-warehouse"></i>
                                    <div>Destination Warehouse</div>
                                  </div>
                                </el-col>
                                <el-col :span="12">
                                  <el-select v-model="shipment.WarehouseId" placeholder="Select" disabled>
                                    <el-option v-for="(item, index) in getWarehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text">
                                    <i class="icon-agent"></i>
                                    <div>Agent</div>
                                  </div>
                                </el-col>
                                <el-col :span="12">
                                  <el-select v-model="shipment.AgentId" placeholder="Select" disabled>
                                    <el-option v-for="item in agentList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <div class="text"><i class="icon-truck"></i>Vehicle Details</div>
                                </el-col>
                                <el-col :span="12">
                                  <el-input v-model="shipment.VehicleDetails" placeholder="..." disabled> </el-input>
                                </el-col>
                              </el-row>
                            </div>
                          </el-col>
                        </div>
                        <div class="line"></div>
                        <el-row>
                          <div class="title">Shipping Details<span class="yellow-dot">.</span></div>
                          <div class="form">
                            <el-row class="right-card">
                              <div class="inline-form">
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Product Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.ProductQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Pallet Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.PalletQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row style="align-items: baseline">
                                  <el-col :span="12">
                                    <div class="text">Pallet Dimensions (CM)</div>
                                  </el-col>
                                  <el-col :span="12" class="dimensions-deactive">
                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.X" placeholder="..." disabled>
                                        <div slot="append">W</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.Y" placeholder="..." disabled>
                                        <div slot="append">L</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.PalletDimensions.Z" placeholder="..." disabled>
                                        <div slot="append">H</div>
                                      </el-input>
                                    </el-col>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Box Quantity</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.BoxQuantity" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Pieces in Box</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                                <el-row style="align-items: baseline">
                                  <el-col :span="12">
                                    <div class="text">Box Dimensions (CM)</div>
                                  </el-col>
                                  <el-col :span="12" class="dimensions-deactive">
                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.X" placeholder="..." disabled>
                                        <div slot="append">W</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.Y" placeholder="..." disabled>
                                        <div slot="append">L</div>
                                      </el-input>
                                    </el-col>

                                    <el-col :span="24">
                                      <el-input v-model="shipment.Box.BoxDimensions.Z" placeholder="..." disabled>
                                        <div slot="append">H</div>
                                      </el-input>
                                    </el-col>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <el-col :span="12">
                                    <div class="text">Weight (kg)</div>
                                  </el-col>
                                  <el-col :span="12">
                                    <el-input v-model="shipment.Box.Weight" placeholder="..." disabled> </el-input>
                                  </el-col>
                                </el-row>
                              </div>
                            </el-row>
                          </div>
                        </el-row>
                        <div class="line"></div>

                        <el-row>
                          <div class="title2">Packing List</div>
                          <div class="upload">
                            <div class="all-files" v-if="shipment.PackingList.FileName">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(shipment.PackingList.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ shipment.PackingList.FileName }}</div>
                                    <div class="file-size">{{ fileSize(shipment.PackingList.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <i class="icon-check"></i><a :href="shipment.PackingList.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </el-row>
                        <div class="line"></div>

                        <el-row>
                          <div class="title2">Supplier Invoice</div>

                          <div class="upload">
                            <div class="all-files" v-if="shipment.SupplierProforma.FileName">
                              <div class="files">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(shipment.SupplierProforma.FileName)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ shipment.SupplierProforma.FileName }}</div>
                                    <div class="file-size">{{ fileSize(shipment.SupplierProforma.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <i class="icon-check"></i><a :href="shipment.SupplierProforma.Url" target="_blank"><i class="icon-download"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-row>
                        <div class="draft">
                          <el-button  type="info" class="add-btn" @click="confirmShipment(shipment.ID)" :loading="loading == 'confirmShipment'" :disabled="shipment.IsSupplierInvoiceSaged"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                        </div>
                      </div>
                    </el-collapse-item>
                  </template>
                  
                </el-collapse>
                <!-- <div class="line"></div>
                <div class="footerbutton">
                  <el-button type="primary" class="add-btn" v-if="!project.MainJob" @click="confirmShipment()" ><i class="icon-check-double-solid"></i> Complete Account</el-button>
                </div> -->
                <div class="comment-body">
                  <div class="comments form">
                    <div class="title">Comments<span class="yellow-dot">.</span></div>
                    <div class="comment-container" v-for="comment in project.Notes.filter((x) => x.Type == 4)" :key="comment.ID">
                      <div class="line"></div>
                      <div class="content">
                        <div class="comment-top">
                          <div class="left">
                            <div class="circle">{{ comment.User.FirstName[0] + comment.User.LastName[0] }}</div>
                            <div class="personel-infos">
                              <div class="user-name">{{ comment.User.FirstName + " " + comment.User.LastName }}</div>
                              <div class="status">
                                <span>{{ getEnumsDisplay("TypeOfAuthority", comment.User.Role) }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                        <div class="comment-content">{{ comment.Content }}</div>
                        <div class="comment-date">{{ $moment(comment.CreatedDate).format("DD-MM-YYYY") }}</div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="comment-container">
                      <div class="comment-text">
                        <div class="left">
                          <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                        </div>
                        <div class="textarea-group">
                          <Mentionable :keys="['@']" :items="items" @apply="onApply(...arguments)" offset="6">
                            <textarea v-model="noteText" :rows="7" placeholder="Add a Comment.." />
                          </Mentionable>
                          <div>use @ to mention someone</div>
                          <span class="error-validation">{{ $errorMessage("Note", projectValidation) }}</span>
                        </div>
                        <el-button @click="comment(4)">Comment</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane v-if="project.SubJobs==0">
              <span slot="label"><i class="document-checked"></i> Quality Control</span>
              <div class="quality-form">
                <template slot="title"> Production Photo<span class="yellow-dot">.</span></template>
                    <div class="upload">
                      <el-upload
                        class="upload-demo"
                        :class="{ disable: (project.ProductionPhoto.Url && project.ProductionPhoto) != null ? true : false }"
                        :disabled="project.ProductionPhoto.Url != null ? true : false"
                        drag
                        :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                        :show-file-list="false"
                        :on-success="handlePhotoSuccess"
                        :headers="{
                          Authorization: $client.defaults.headers.common['Authorization'],
                        }"
                        multiple
                      >
                        <i class="icon-cloudarrow"></i>
                        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        <div class="el-upload__tip" slot="tip">JPG files with a size less than 5mb</div>
                      </el-upload>
                      <span class="error-validation">{{ $errorMessage("ProductionPhoto", projectValidation) }}</span>

                      <div class="review" v-if="project.ProductionPhoto.Url">
                        <img :src="project.ProductionPhoto.Url" alt="" />
                      </div>
                      <div class="all-files" v-if="project.ProductionPhoto.Url">
                        <div class="files">
                          <div class="file">
                            <div class="file-icon">
                              <img :src="getExtension(project.ProductionPhoto.FileName)" />
                            </div>
                            <div class="filecontent">
                              <div class="file-name">{{ project.ProductionPhoto.FileName }}</div>
                              <div class="file-size">{{ fileSize(project.ProductionPhoto.FileSize) }}</div>
                            </div>
                          </div>
                          <div class="icon-status">
                            <div class="icon-status">
                              <a :href="project.ProductionPhoto.Url" target="_blank"><i class="icon-download"></i></a>
                            </div>
                            <div class="icon-status" @click="clearDesignPhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
                          </div>
                        </div>
                      </div>
                      <div class="mini-form checkboxes">
                        <div class="title">Production Confirmation<span class="yellow-dot">.</span></div>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.PantoneColoursCodes">Pantone Colour Codes</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.ProductDimensions">Product Dimensions</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.MainDesign">Main Design</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.Paperweight">Paperweight</el-checkbox>
                        <el-checkbox v-model="project.ProductionPhotoConfirmation.Logo">Packaging Source Logo</el-checkbox>
                        <span class="error-validation">{{ $errorMessage("Logo", projectValidation) }}</span>
                      </div>
                      <div class="line"></div>
                      <div class="draft">
                        <el-button type="info" class="add-btn" @click="saveDesignPhoto" :loading="loading == 'productionPhoto'"><i v-if="loading == false" class="icon-floppy-disk-solid"></i> Confirm</el-button>
                      </div>
                      <div class="line"></div>
                    </div>
              </div>
            </el-tab-pane> -->
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-clock-rotate"></i> Updates <span class="notifications">{{ project.Histories.length > 9 ? "9+" : project.Histories.length }}</span>
              </span>
              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in project.Histories" :key="index">
                    <el-card>
                      <div class="content">{{ item.Note }}</div>
                      <div class="updates-bottom">
                        <div class="bottom">
                          <div class="circle">{{ item.User.FirstName[0] + "" + item.User.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.User.FullName }}</div>
                            <div class="status">
                              <span>{{ getEnumsDisplay("TypeOfAuthority", item.User.Role) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
import { Mentionable } from "vue-mention";
export default {
  data() {
    return {
      isTotalProductionValid: true,
      isShippmentQuantityValid:true,
      isValidOddBoxQuantity:true,
      isIncludeOdd:false,
      noteText: null,
      project: null,
      quotation: null,
      proforma: null,
      quotationList: [],
      UnitPriceHistoriesSort: [],
      proformaList: [],
      withoutProformaData:[],
      items: [],
      projectValidation: [],
      shipmentForm: null,
      hedefID: [],
      loading: false,
      timeout: null,
      subQuantity:null,
      changePage:false,
      pickerOptions: {
        firstDayOfWeek:1
      },
      disableCreateShippment:false
    };
  },
  props: ["form", "id"],
  components: {
    Mentionable,
  },
  async created() {
    await this.checkedId();
    this.projectValidation = [];
    await Promise.all([
      this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerm: this.id }),
      this.$store.dispatch("getQuotationsList", { Page: 1, PageSize: 999999, SearchTerm: this.id }),
      this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null }),
      this.$store.dispatch("getWarehouseList"),
      this.$store.dispatch("getSuppliersList", { Page: 1, PageSize: 999999, SearchTerm: null }),
      this.$store.dispatch("getAgentList", { Page: 1, PageSize: 999999, SearchTerm: null }),
      this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null }),
    ]);
    this.getUserList
      .filter((x) => x.IsDeactivated != true)
      .map((x) => {
        this.items.push({
          value: x.FirstName + x.LastName,
          label: x.FirstName + " " + x.LastName,
          id: x.ID,
        });
      });

      
      
      console.log(this.project)
  },
  methods: {
    disableShippment(){
      if(this.project.Operation.RemainingQuantity.FinalProductionQuantity == 0 && this.project.Operation.RemainingQuantity.OddBoxes.length   == 0
                && this.project.Operation.RemainingQuantity.PalletQuantity == 0 && this.project.Operation.RemainingQuantity.BoxQuantity == 0){
        this.disableCreateShippment=true;
      }
      else{
        this.disableCreateShippment=false
      }
    },
    isMain(){
      if(this.project.SubJobs.length > 0)
        this.changePage=true
    },
    emptyIfNull(val){
      return val || ""
    },
    getSizeString() {
      var x;

      if (this.project.Product.Unit == 0) {
        x = '"';
      } else if (this.project.Product.Unit == 1) {
        x = "oz";
      } else if (this.project.Product.Unit == 2) {
        x = "Ø";
      } else if (this.project.Product.Unit == 3) {
        x = "cc";
      } else {
        x = "";
      }
      if (this.project.Product.Diameter) {
        this.project.DesignInformation.Size=this.project.Product.DiameterValue + x;
        return this.project.Product.DiameterValue + x;
      } else {
        var size =this.emptyIfNull(this.project.Product.Width) + "x" + this.emptyIfNull(this.project.Product.Length) + (this.project.Product.Height==null ? "" : "x" + this.project.Product.Height)+ "cm ";
        this.project.DesignInformation.Size=size;
        return size;
        // this.project.DesignInformation.Size=this.project.Product.Width + "x" + this.project.Product.Length + "x" + this.project.Product.Height + "cm ";
        // return this.project.Product.Width + "x" + this.project.Product.Length + "x" + this.project.Product.Height + "cm ";
      }
    },
    async createSubproject(){
      this.loading = "createSubProject";
      
      if (this.subQuantity==null) {
        this.projectValidation.push({
          Key: "Quantity",
          Value: "Please fill this field.",
        });
      }else{
        this.projectValidation=[]
      }

      if(this.projectValidation.length >0){
        this.loading =false;
        return;
      }

      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          Id:this.project.ID,
          Quantity:this.subQuantity
        };
        console.log(payload)
        var res = await this.$client.post("/Job/CreateSubproject", payload);
        if (res.data.HasError) {
          console.log(res.data)
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.close();
        }         
        this.loading = false;
      }, 500);
    },
    async saveProductionLabel() {
      //pallet
      this.loading = "boxLabel";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          JobId: this.id,
          LabelFile: {
            Url: this.project.Production.LabelFile.Url,
            FileName: this.project.Production.LabelFile.FileName,
            FileSize: this.project.Production.LabelFile.FileSize,
          },
        };
        var res = await this.$client.post("/Job/SaveProductionLabel", payload);
        this.loading = false;
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          await this.getProject();
        }
      }, 500);
    },
    async saveProductIdStickerFile() {
      //box
      this.loading = "productIdSticker";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          JobId: this.id,
          ProductIdStickerFile: {
            Url: this.project.Production.ProductIdStickerFile.Url,
            FileName: this.project.Production.ProductIdStickerFile.FileName,
            FileSize: this.project.Production.ProductIdStickerFile.FileSize,
          },
        };
        var res = await this.$client.post("/Job/SaveProductIdSticker", payload);

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    // exportPalletLabel() {
    //   this.loading="generatePaletLabel";
    //   var id = this.project.ID;
    //   var token = this.$cookies.get("token")
    //   this.$client.get("/Job/GeneratePaletLabel", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
    //       console.log(response)
    //       const blob = new Blob([response.data], { type: "application/pdf" });
    //       const link = document.createElement("a");
    //       link.download ="paletLabel"
    //       // link.target="_blank";
    //       link.href = URL.createObjectURL(blob);
    //       link.click();
    //       URL.revokeObjectURL(link.href);
    //       this.loading = false;
    //   });
    // },

    exportPalletLabel() {
      this.loading="generatePaletLabel";
      var id = this.project.ID;
      var token = this.$cookies.get("token")
      this.$client.get("/Job/GeneratePaletLabel", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
          const link = document.createElement("a");
          link.download ="paletLabel"
          link.download = this.project.Product.SKU + " Palet Etiketi";
          // link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    exportBoxLabel() {
      if(this.project.Product.ProductId == null){
        this.$message.error("Product Id should be entered to create barcode");
        return;
      }
      
      this.loading="generateBoxLabel";
      var id = this.project.ID;
      var token = this.$cookies.get("token")
      console.log(token)
      this.$client.get("/Job/GenerateBoxLabelExportExcel", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.project.Product.SKU + " Koli Etiketi";
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading=false;
      });
      
      // this.getProject();
    },
    // exportBoxLabel() {
    //   this.loading="generateBoxLabel";
    //   var id = this.project.ID;
    //   var token = this.$cookies.get("token")
    //   console.log(token)
    //   this.$client.get("/Job/GenerateBoxLabelExportExcel", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
    //       console.log(response)
    //       const blob = new Blob([response.data], { type: "application/pdf" });
    //       const link = document.createElement("a");
    //       // link.target="_blank";
    //       link.download ="boxLabel"
    //       link.href = URL.createObjectURL(blob);
    //       link.click();
    //       URL.revokeObjectURL(link.href);
    //       this.loading = false;
    //   });
    //   this.getProject();
    // },
    // exportBoxLabel() {
    //   if (this.timeout != null) clearTimeout(this.timeout);
    //   this.timeout = setTimeout(async () => {
    //       var payload = {
    //         Id: this.id,
    //       };
    //     var res = await this.$client.post("/Job/GenerateBoxLabel",payload);
    //     if (res.data.HasError) {
    //       this.$message.error(res.data.Message);
    //     } else {
    //       this.$message.success(res.data.Message);
    //       var id = this.id;
    //       var token = this.$cookies.get("token")
    //       this.project.Production.ProductIdStickerFile.FileName = `BoxLabel.pdf`;
    //       this.project.Production.ProductIdStickerFile.FileSize = 500000;
    //       this.project.Production.ProductIdStickerFile.Url = `https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/boxlabel.html?id=${id}=idtoken-seperator=${token}`;
    //       window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/boxlabel.html?id=${id}=idtoken-seperator=${token}`);
    //     }
    //     this.loading = false;
    //   }, 500);
      
    // },
    createNewShipment() {
      this.shipmentForm = {
        CollectionDate: null,
        EstimatedArrivalDateCustoms: null,
        EstimatedArrivalDateWarehouse: null,
        VehicleDetails: null,
        AgentId: null,
        WarehouseId: null,

        ShippingForm: {
          FileName: null,
          FileSize: null,
          Url: null,
        },
        ShipmentProductQuantity: 0, //this.project.Operation.RemainingQuantity.FinalProductionQuantity,
        PalletQuantity: 0, //this.project.Operation.RemainingQuantity.PalletQuantity,
        PalletDimensions: {
          X: this.project.Operation.RemainingQuantity.PalletDimensions.X,
          Y: this.project.Operation.RemainingQuantity.PalletDimensions.Y,
          Z: this.project.Operation.RemainingQuantity.PalletDimensions.Z,
        },
        BoxQuantity: this.project.Operation.RemainingQuantity.BoxQuantity,

        Box: {
          PiecesInBox: this.project.Operation.RemainingQuantity.Box.PiecesInBox,
          BoxDimensions: {
            X: this.project.Operation.RemainingQuantity.Box.BoxDimensions.X,
            Y: this.project.Operation.RemainingQuantity.Box.BoxDimensions.Y,
            Z: this.project.Operation.RemainingQuantity.Box.BoxDimensions.Z,
          },
          Weight: this.project.Operation.RemainingQuantity.Box.Weight,
        },
        OddBoxes:[],
        PackingList: {
          FileName: null,
          FileSize: null,
          Url: null,
        },
        SupplierProforma: {
          FileName: null,
          FileSize: null,
          Url: null,
        },
      };
      this.project.Operation.RemainingQuantity.OddBoxes.forEach(oddBox => {
       this.shipmentForm.OddBoxes.push(oddBox);
      });
    },
    // async saveConfirmDesignInfo() {
    //   var payload = {
    //     ID: this.id,
    //     ProductDimensions: this.project.DesignProductionConfirmation.ProductDimensions,
    //     PantoneColoursCodes: this.project.DesignProductionConfirmation.PantoneColoursCodes,
    //     MainDesign: this.project.DesignProductionConfirmation.MainDesign,
    //     Paperweight: this.project.DesignProductionConfirmation.Paperweight,
    //     Logo:this.project.DesignProductionConfirmation.Logo,
    //   };
    //   var res = await this.$client.post("/Job/SaveDesignProductionConfirmation", payload);
    //   if (res.data.HasError) {
    //     this.$message.error(res.data.Message);
    //   } else {
    //     this.$message.success(res.data.Message);
    //   }
    // },
    async confirmSupplierProforma() {
      var payload = {
        ID: this.id,
        ConfirmedDate: this.project.Production.SupplierProforma.ConfirmedDate,
        ConfirmedUser: this.project.Production.SupplierProforma.ConfirmedUser,
      };
      var res = await this.$client.post("/Job/ConfirmSupplierProforma", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.getProject();
      }
    },
    getClassOfferStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "warning";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "danger";
          break;
        case 4:
          res = "info";
          break;
        case 5:
          res = "info";
          break;
        case 6:
          res = "success";
          break;
        case 7:
          res = "danger";
          break;
      }
      return res;
    },
    getClassProformaStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "warning";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "danger";
          break;
        case 4:
          res = "info";
          break;
        case 5:
          res = "success";
          break;
        case 6:
          res = "danger";
          break;
      }
      return res;
    },
    
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "success";
          break;
        case 3:
          res = "info";
          break;
        case 4:
          res = "warning";
          break;
        case 5:
          res = "danger";
          break;
        case 6:
          res = "warning";
          break;
        case 7:
          res = "info";
          break;
        case 8:
          res = "info";
          break;
        case 9:
          res = "success";
          break;
        case 10:
          res = "danger";
          break;
        case 11:
          res = "success";
          break;
        case 12:
          res = "success";
          break;
        case 13:
          res = "success";
          break;
        case 14:
          res = "info";
          break;
        case 15:
          res = "info";
          break;
        case 16:
          res = "info";
          break;
        case 17:
          res = "info";
          break;
        case 18:
          res = "info";
          break;
        case 19:
          res = "danger";
          break;
        case 20:
          res = "info";
          break;
        case 21:
          res = "info";
          break;
        case 33:
          res = "success";
          break;
        case 34:
          res = "info";
          break;
        case 35:
          res = "warning";
          break;
        case 36:
          res = "warning";
          break;
        case 37:
          res = "warning";
          break;
        case 38:
          res = "info";
          break;
        case 39:
          res = "info";
          break;
        case 40:
          res = "info";
          break;
        case 41:
          res = "warning";
          break;
        case 42:
          res = "warning";
          break;
        case 43:
          res = "info";
          break;
        case 44:
          res = "success";
          break;
        case 45:
          res = "warning";
          break;
        case 46:
          res = "success";
          break;
        case 47:
        res = "warning";
        break;
      }
      return res;
    },
    addOddBox() {
      this.project.Production.TotalProductionDetails.OddBoxes.push({PiecesInBox:0,BoxQuantity:0});
    },
    deleteOddBox(index) {
      this.project.Production.TotalProductionDetails.OddBoxes.splice(index,1);
      this.checkTotalProduction()
    },
    addShipmentOddBox() {
      this.shipmentForm.OddBoxes=[];
      this.project.Operation.RemainingQuantity.OddBoxes.forEach(oddBox => {
       this.shipmentForm.OddBoxes.push(oddBox);
      });
      this.checkShippmentQuantity();
    },
    deleteShipmentOddBox(index) {
      console.log (this.shipmentForm.OddBoxes);
      console.log(this.project.Operation.RemainingQuantity.OddBoxes)
      this.shipmentForm.OddBoxes.splice(index,1);
      this.checkShippmentQuantity()
      console.log (this.shipmentForm.OddBoxes);
      console.log(this.project.Operation.RemainingQuantity.OddBoxes)
    },
    addNewCustomerColor() {
        this.project.DesignInformation.PantoneColors.push("");
    },
    addNewSupplierColor() {
        this.project.SupplierDesignInformation.PantoneColors.push("");
    },

    clearNoteText() {
      this.noteText = null;
    },
    onApply(...args) {
      this.hedefID.push(args[0].id);
    },
    async comment(type) {
      var payload = {
        JobId: this.id,
        Note: this.noteText,
        Type: type,
        NotificationOwnerIds: this.hedefID,
      };

      var res = await this.$client.post("/Job/AddNote", payload);
      this.projectValidation = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
      await this.getProject();
      this.noteText = null;
      this.hedefID = [];
    },
    handleLabelSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.Production.LabelFile = obj;
    },
    handlePackingListFileSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.shipmentForm.PackingList = obj;
    },
    handlePreviewSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.DesignInformation.PreviewFile = obj;
      this.saveDesignPreview()
    },
    handleCustomerSamplePhotoSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.DesignInformation.SamplePhoto = obj;
    },
    handleSupplierSamplePhotoSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.SupplierDesignInformation.SamplePhoto = obj;
    },
    handlePhotoSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.ProductionPhoto = obj;
    },
    handleCustomerSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.DesignInformation.File = obj;
    },
    handleSupplierSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.SupplierDesignInformation.File = obj;
    },

    handleSupplierProformaFileSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.shipmentForm.SupplierProforma = obj;
    },
    handleSupplierProformaSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.Production.SupplierProforma.SupplierProformaFile = obj;
    },

    handleProductIdStickerFileSuccess(response) {
      var obj = {
        FileName: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.project.Production.ProductIdStickerFile = obj;
    },
    async saveDesignInformation() {
      this.projectValidation = [];
      this.loading = "customerDesignDetails";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          ID: this.id,
          ProductReference:this.project.DesignInformation.ProductReference,
          Size: this.project.DesignInformation.Size,
          // RawMaterial: this.project.DesignInformation.RawMaterial,
          PantoneColors: this.project.DesignInformation.PantoneColors,
          // Details: this.project.DesignInformation.Details,
          File: this.project.DesignInformation.File,
          DesignNote: this.project.DesignInformation.DesignNote,
          SamplePhoto: this.project.DesignInformation.SamplePhoto,
          DieCut: this.project.DesignInformation.DieCut,
          ProductDimensions: this.project.DesignInformation.ProductDimensions,
          PantoneColoursCodes: this.project.DesignInformation.PantoneColoursCodes,
          Positioning: this.project.DesignInformation.Positioning,
          Gsm: this.project.DesignInformation.Gsm,
          Lamination: this.project.DesignInformation.Lamination,
          Handle: this.project.DesignInformation.Handle,
          Type: this.project.DesignInformation.Type,
        };
        var res = await this.$client.post("/Job/SaveDesignInformation", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    async saveSupplierDesignInformation() {
      this.projectValidation = [];
      this.loading = "supplierDesignDetails";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          ID: this.id,
          ProductReference:this.project.SupplierDesignInformation.ProductReference,
          Size: this.project.SupplierDesignInformation.Size,
          // RawMaterial: this.project.SupplierDesignInformation.RawMaterial,
          PantoneColors: this.project.SupplierDesignInformation.PantoneColors,
          // Details: this.project.SupplierDesignInformation.Details,
          File: this.project.SupplierDesignInformation.File,
          DesignNote: this.project.SupplierDesignInformation.DesignNote,
          SamplePhoto: this.project.SupplierDesignInformation.SamplePhoto,
          DieCut: this.project.SupplierDesignInformation.DieCut,
          ProductDimensions: this.project.SupplierDesignInformation.ProductDimensions,
          PantoneColoursCodes: this.project.SupplierDesignInformation.PantoneColoursCodes,
          Positioning: this.project.SupplierDesignInformation.Positioning,
          Gsm: this.project.SupplierDesignInformation.Gsm,
          Lamination: this.project.SupplierDesignInformation.Lamination,
          Handle: this.project.SupplierDesignInformation.Handle,
          Type: this.project.SupplierDesignInformation.Type,
          // ProductDimensions: this.project.DesignProductionConfirmation.ProductDimensions,
          // PantoneColoursCodes: this.project.DesignProductionConfirmation.PantoneColoursCodes,
          // MainDesign: this.project.DesignProductionConfirmation.MainDesign,
          // Paperweight: this.project.DesignProductionConfirmation.Paperweight,
          // Logo: this.project.DesignProductionConfirmation.Logo,
        };
        var res = await this.$client.post("/Job/SaveSupplierDesignInformation", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },

    async saveSupplierInfoFiles() {
      this.projectValidation = [];
      var fileObj = {
        Url: this.project.Operation.SupplierInfoFile.Url,
        FileName: this.project.Operation.SupplierInfoFile.FileName,
        FileSize: this.project.Operation.SupplierInfoFile.FileSize,
      };
      var payload = {
        JobId: this.id,
        SupplierInfoFile: this.project.Operation.SupplierInfoFile.Url ? fileObj : null,
      };
      var res = await this.$client.post("/Job/SaveSupplierInfo", payload);
      this.projectValidation = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.getProject();
      }
    },

    clearProductIdStickerFile() {
      this.project.Production.ProductIdStickerFile.FileSize = 0;
      this.project.Production.ProductIdStickerFile.Url = null;
      this.project.Production.ProductIdStickerFile.FileName = null;
    },

    clearSupplierProformaFile() {
      this.shipmentForm.SupplierProforma.FileSize = 0;
      this.shipmentForm.SupplierProforma.Url = null;
      this.shipmentForm.SupplierProforma.FileName = null;
    },
    async saveDesignPreview() {
      this.projectValidation = [];
      this.loading = "designPreview";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var fileObj = {
          Url: this.project.DesignInformation.PreviewFile.Url,
          FileName: this.project.DesignInformation.PreviewFile.FileName,
          FileSize: this.project.DesignInformation.PreviewFile.FileSize,
        };
        var payload = {
          JobId: this.id,
          DesignPreviewFile: this.project.DesignInformation.PreviewFile.Url ? fileObj : null,
        };
        var res = await this.$client.post("/Job/SaveDesignPreview", payload);

        this.projectValidation = res.data.ValidationErrors;
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },

    clearProductionLabel() {
      this.project.Production.LabelFile.FileSize = 0;
      this.project.Production.LabelFile.Url = null;
      this.project.Production.LabelFile.FileName = null;
    },
    clearSupplierInfoFile() {
      this.project.Operation.SupplierInfoFile.FileSize = 0;
      this.project.Operation.SupplierInfoFile.Url = null;
      this.project.Operation.SupplierInfoFile.FileName = null;
    },

    async savePackingListFile() {
      var payload = {
        JobId: this.id,
        PackingListFile: {
          Url: this.project.Operation.PackingListFile.Url,
          FileName: this.project.Operation.PackingListFile.FileName,
          FileSize: this.project.Operation.PackingListFile.FileSize,
        },
      };
      var res = await this.$client.post("/Job/SavePackingList", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getProject();
      }
    },
    clearPackingListFile() {
      this.shipmentForm.PackingList.FileSize = 0;
      this.shipmentForm.PackingList.Url = null;
      this.shipmentForm.PackingList.FileName = null;
    },

    clearDesignPhoto() {
      this.project.ProductionPhoto.FileSize = 0;
      this.project.ProductionPhoto.Url = null;
      this.project.ProductionPhoto.FileName = null;
    },
    clearLabelFile() {
      this.project.Production.LabelFile.FileSize = 0;
      this.project.Production.LabelFile.Url = null;
      this.project.Production.LabelFile.FileName = null;
    },

    clearSupplierDesignInformation() {
      this.project.SupplierDesignInformation.File = null;
    },
    clearPreviewPhoto() {
      this.project.SupplierDesignInformation.PreviewFile.FileSize = 0;
      this.project.SupplierDesignInformation.PreviewFile.Url = null;
      this.project.SupplierDesignInformation.PreviewFile.FileName = null;
      
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          Id: this.id
        };
        var res = await this.$client.post("/Job/ClearDesignPreview", payload);

        this.projectValidation = res.data.ValidationErrors;
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    clearCustomerSamplePhoto() {
      this.project.DesignInformation.SamplePhoto.FileSize = 0;
      this.project.DesignInformation.SamplePhoto.Url = null;
      this.project.DesignInformation.SamplePhoto.FileName = null;
    },
    clearSupplierSamplePhoto() {
      this.project.SupplierDesignInformation.SamplePhoto.FileSize = 0;
      this.project.SupplierDesignInformation.SamplePhoto.Url = null;
      this.project.SupplierDesignInformation.SamplePhoto.FileName = null;
    },
    async saveDesignPhoto() {
      this.projectValidation = [];
      this.loading = "productionPhoto";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var fileObj = {
          FileSize: this.project.ProductionPhoto.FileSize,
          FileName: this.project.ProductionPhoto.FileName,
          Url: this.project.ProductionPhoto.Url,
        };

        var payload = {
          JobId: this.id,
          ProductionPhoto: this.project.ProductionPhoto.Url ? fileObj : null,
          ProductionPhotoConfirmation: {
            ProductDimensions: this.project.ProductionPhotoConfirmation.ProductDimensions,
            PantoneColoursCodes: this.project.ProductionPhotoConfirmation.PantoneColoursCodes,
            MainDesign: this.project.ProductionPhotoConfirmation.MainDesign,
            Paperweight: this.project.ProductionPhotoConfirmation.Paperweight,
            Logo: this.project.ProductionPhotoConfirmation.Logo,
          },
        };
        var res = await this.$client.post("/Job/SaveProductionPhoto", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    async changeStartingDate() {
      var payload = {
        ID: this.id,
        StartingDate: this.project.StartingDate,
      };
      var res = await this.$client.post("/Job/SaveProjectStartingDate", payload);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
    },
    async sampleRequestSave() {
      var payload = {
        JobId: this.id,
        SampleRequest: this.project.SampleRequest,
        SampleRequestNote: this.project.SampleRequestNote,
      };
      var res = await this.$client.post("/Job/ToggleDesignSample", payload);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getProjectsList");
      }
    },
    async changeSupplier() {
      var payload = {
        ID: this.id,
        SupplierId: this.project.SupplierId,
      };
      var res = await this.$client.post("/Job/SaveSupplier", payload);
      console.log(res)
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getProject();
        await this.$store.dispatch("getProjectsList");
      }
    },
    async changeProductManager() {
      var payload = {
        ID: this.id,
        ProductManagerId: this.project.ProductManagerId,
      };
      var res = await this.$client.post("/Job/SaveProductManager", payload);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getProjectsList");
      }
    },
    // async saveShipmentDate1(index){
    //   console.log('asd');
    //   var payload ={
    //     ShipmentId:this.project.Operation.Shipments[index].ID,
    //     EstimatedArrivalDateWarehouse:this.project.Operation.Shipments[index].EstimatedArrivalDateWarehouse,
    //     EstimatedArrivalDateCustoms:this.project.Operation.Shipments[index].EstimatedArrivalDateCustoms,
    //   };
    //   var res = await this.$client.post("/Job/SaveShipmentDate",payload);
    //    if (res.data.HasError) {
    //     this.$message.error(res.data.Message);
    //   } else {
    //     this.$message.success(res.data.Message);
    //     await this.$store.dispatch("getProjectsList");
    //   }
    // },
    //     async saveShipmentDate2(index){
    //   console.log('asd');
    //   var payload ={
    //     ShipmentId:this.project.Operation.Shipments[index].ID,
    //     EstimatedArrivalDateWarehouse:this.project.Operation.Shipments[index].EstimatedArrivalDateWarehouse,
    //     EstimatedArrivalDateCustoms:this.project.Operation.Shipments[index].EstimatedArrivalDateCustoms,
    //   };
    //   var res = await this.$client.post("/Job/SaveShipmentDate",payload);
    //    if (res.data.HasError) {
    //     this.$message.error(res.data.Message);
    //   } else {
    //     this.$message.success(res.data.Message);
    //     await this.$store.dispatch("getProjectsList");
    //   }
    // },
    async changeTimeline() {
      var payload = {
        ID: this.id,
        TimeLine: this.project.TimeLine,
      };
      var res = await this.$client.post("/Job/SaveProjectTimeline", payload);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
    },
    async getProject() {
      // this.projectValidation = [];
      await new Promise(resolve => setTimeout(resolve, 500));
      var res = await this.$store.dispatch("getOneProject", this.id);
      console.log(res)
      if (!res.HasError) {
        this.project = res;
        this.project.SupplierUnitPrice = this.project.SupplierUnitPrice ? this.project.SupplierUnitPrice.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 5 }) : null;
        if(this.project.MainJob){
          this.UnitPriceHistoriesSort = this.project.Product.UnitPriceHistories.reverse();
        }
        else{
          this.UnitPriceHistoriesSort = this.project.Product.UnitPriceHistories.filter((x)=>{x.JobId==this.project.ID}).reverse();
        }
      } else {
        this.close();
      }
      if (this.project.ProformaId != null && this.project.ProformaId != " - ") {
        console.log(this.project.ProformaId)
        var res2 = await this.$store.dispatch("getOneProforma", this.project.ProformaId);
        this.proforma = res2;
        this.proformaList = [res2];
      }else{
        this.withoutProformaData = [{Quantity:this.project.Quantity,UnitPrice: this.project.UnitPrice}]
        console.log(this.withoutProformaData)
      } 
      // if (this.proforma.QuotationId != null && this.proforma != undefined) {
      //   console.log(this.proforma.QuotationId)
      //   var res3 = await this.$store.dispatch("getOneQuotation", this.proforma.QuotationId);
      //   this.quotation = res3;
      //   this.quotationList = [res3];
      // }

      if(this.project.MainJob){
        this.changePage=true;
      }

      if(this.project.DesignInformation.PantoneColors.length <1)
      this.project.DesignInformation.PantoneColors.push("");

      if(this.project.SupplierDesignInformation.PantoneColors.length <1)
      this.project.SupplierDesignInformation.PantoneColors.push("");
      // this.addNewSupplierColor();
      // this.addNewCustomerColor();
      this.disableShippment()
    },
    async close() {
      await this.$store.dispatch("getProjectsList");

      this.$emit("close");
    },

    async saveShipment() {
      this.projectValidation = [];

      var fileObj1 = {
        Url: this.shipmentForm.ShippingForm.Url,
        FileName: this.shipmentForm.ShippingForm.FileName,
        FileSize: this.shipmentForm.ShippingForm.FileSize,
      };
      var fileObj2 = {
        Url: this.shipmentForm.PackingList.Url,
        FileName: this.shipmentForm.PackingList.FileName,
        FileSize: this.shipmentForm.PackingList.FileSize,
      };
      var fileObj3 = {
        Url: this.shipmentForm.SupplierProforma.Url,
        FileName: this.shipmentForm.SupplierProforma.FileName,
        FileSize: this.shipmentForm.SupplierProforma.FileSize,
      };
      var payload = {
        JobId: this.id,
        ...this.shipmentForm,
        ShippingForm: this.shipmentForm.ShippingForm.Url ? fileObj1 : null,
        PackingList: this.shipmentForm.PackingList.Url ? fileObj2 : null,
        SupplierProforma: this.shipmentForm.SupplierProforma.Url ? fileObj3 : null,
      };

      this.$confirm("Are you sure save shipment?", "Warning", {
        confirmButtonText: "Save",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "Back",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/SaveOperationShipment", payload);
          this.projectValidation = res.data.ValidationErrors;
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
          await this.getProject();
            this.shipmentForm = null;
          }
          this.disableShippment()
        })
        .catch(() => {});
    },
    async saveProductionTimeline() {
      this.projectValidation = [];
      this.projectValidation = [];
      this.loading = "productTimeline";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          JobId: this.id,
          ProductionStartDate: this.project.Production.ProductTimeline.ProductionStartDate,
          EstimatedEndDate: this.project.Production.ProductTimeline.EstimatedEndDate,
          // ActualEndDate: this.project.Production.ProductTimeline.ActualEndDate,
          ConfirmationDate: this.project.Production.ProductTimeline.ConfirmationDate,
          ConfirmationNo: this.project.Production.ProductTimeline.ConfirmationNo,
        };
        var res = await this.$client.post("/Job/ProductionSaveTimeline", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
         await this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    async saveProduction() {
      this.projectValidation = [];
      this.loading = "supplierProforma";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var fileObj = {
          FileSize: this.project.Production.SupplierProforma.SupplierProformaFile.FileSize,
          FileName: this.project.Production.SupplierProforma.SupplierProformaFile.FileName,
          Url: this.project.Production.SupplierProforma.SupplierProformaFile.Url,
        };
        var payload = {
          JobId: this.id,

          SupplierProformaFile: this.project.Production.SupplierProforma.SupplierProformaFile.Url ? fileObj : null,
          SupplierUnitPrice: this.project.SupplierUnitPrice,
        };
        var res = await this.$client.post("/Job/ProductionSave", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
        await  this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    clearSupplierProforma() {
      this.project.Production.SupplierProforma.SupplierProformaFile.FileSize = null;
      this.project.Production.SupplierProforma.SupplierProformaFile.FileName = null;
      this.project.Production.SupplierProforma.SupplierProformaFile.Url = null;
      this.project.SupplierUnitPrice = null;
    },
    async saveTotalProductionDetails() {
      
      if (!this.isTotalProductionValid) {
        return;
      }

      this.loading = "totalProductionDetails";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          ID: this.id,
          ActualEndDate: this.project.Production.ProductTimeline.ActualEndDate,
          OrderQuantity: this.project.Production.TotalProductionDetails.OrderQuantity,
          FinalProductionQuantity: this.project.Production.TotalProductionDetails.FinalProductionQuantity === "" ? null : this.project.Production.TotalProductionDetails.FinalProductionQuantity,
          PalletQuantity: this.project.Production.TotalProductionDetails.PalletQuantity === "" ? null : this.project.Production.TotalProductionDetails.PalletQuantity,
          PalletDimensions: {
            X: this.project.Production.TotalProductionDetails.PalletDimensions.X === "" ? null : this.project.Production.TotalProductionDetails.PalletDimensions.X,
            Y: this.project.Production.TotalProductionDetails.PalletDimensions.Y === "" ? null : this.project.Production.TotalProductionDetails.PalletDimensions.Y,
            Z: this.project.Production.TotalProductionDetails.PalletDimensions.Z === "" ? null : this.project.Production.TotalProductionDetails.PalletDimensions.Z,
          },
          BoxQuantity: this.project.Production.TotalProductionDetails.BoxQuantity === "" ? null : this.project.Production.TotalProductionDetails.BoxQuantity,
          Box: {
            PiecesInBox: this.project.Production.TotalProductionDetails.Box.PiecesInBox === "" ? 0 : this.project.Production.TotalProductionDetails.Box.PiecesInBox,
            PiecesInOddBox: this.project.Production.TotalProductionDetails.Box.PiecesInOddBox === "" ? 0 : this.project.Production.TotalProductionDetails.Box.PiecesInOddBox,
            BoxDimensions: {
              X: this.project.Production.TotalProductionDetails.Box.BoxDimensions.X === "" ? null : this.project.Production.TotalProductionDetails.Box.BoxDimensions.X,
              Y: this.project.Production.TotalProductionDetails.Box.BoxDimensions.Y === "" ? null : this.project.Production.TotalProductionDetails.Box.BoxDimensions.Y,
              Z: this.project.Production.TotalProductionDetails.Box.BoxDimensions.Z === "" ? null : this.project.Production.TotalProductionDetails.Box.BoxDimensions.Z,
            },
            Weight: this.project.Production.TotalProductionDetails.Box.Weight === "" ? 0 : this.project.Production.TotalProductionDetails.Box.Weight,
          },
          OddBoxes:this.project.Production.TotalProductionDetails.OddBoxes
        };
        var res = await this.$client.post("/Job/SaveTotalProductionDetails", payload);
        this.projectValidation = res.data.ValidationErrors;

        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    async completedDesign() {
      var payload = {
        Id: this.id,
      };
      this.$confirm("Are you sure to mark design completed?", "Warning", {
        confirmButtonText: "Confirm",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "Back",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/CompleteDesign", payload);
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.getProject();
          }
        })
        .catch(() => {});
    },
    async completedProduction() {
      var payload = {
        Id: this.id,
      };

      this.$confirm("Are you sure to mark production completed?", "Warning", {
        confirmButtonText: "Confirm",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "Back",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/CompleteProduction", payload);
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.getProject();
          }
        })
        .catch(() => {});
    },
    async completedOperation() {
      var payload = {
        Id: this.id,
      };

      this.$confirm("Are you sure to mark operation completed?", "Warning", {
        confirmButtonText: "Confirm",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "Back",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/CompleteOperation", payload);
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.getProject();
            await this.$store.dispatch("getProjectsList");
          }
        })
        .catch(() => {});
    },
    async confirmShipment(shipmentId) {
      var payload = {
        Id: this.id,
        ShipmentId:shipmentId
      };

      this.$confirm("Are you sure to mark confirmed?", "Warning", {
        confirmButtonText: "Confirm",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "Back",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/ConfirmShipment", payload);
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.getProject();
            await this.$store.dispatch("getProjectsList");
          }
        })
        .catch(() => {});
    },
    async updateSKU() {
      var payload = {
        JobId: this.id,
        SKU: this.project.Product.SKU,
      };
      var res = await this.$client.post("/Job/UpdateSKU", payload);
      this.projectValidation = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getProjectsList");
        this.projectValidation = [];
      }
    },
    async updateProductId() {
      var payload = {
        JobId: this.id,
        ProductId: this.project.Product.ProductId,
      };
      var res = await this.$client.post("/Job/UpdateProductId", payload);
      this.projectValidation = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getProjectsList");
        this.projectValidation = [];
      }
    },
    seePrewiev(id) {
      var token = this.$cookies.get("token");
      this.loading = "exportLoading" + id;
      this.$client.get("/Proforma/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    // seePrewiev(id) {
    //   this.loading = "exportLoading" + id;
    //   if (this.timeout != null) clearTimeout(this.timeout);
    //   this.timeout = setTimeout(async () => {
    //     var token = this.$cookies.get("token")
    //     window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/proforma.html?id=${id}=idtoken-seperator=${token}`);
    //     this.loading = false;
    //   }, 500);
    // },
    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.getProject();
        } else {
          this.close();
        }
        this.loading = false;
        this.isMain()
      }, 500);
      
    },
    calculateTotal() {
      if(this.isIncludeOdd){
        this.shipmentForm.ShipmentProductQuantity = this.shipmentForm.Box.PiecesInBox * this.shipmentForm.BoxQuantity;
      }else{
        this.shipmentForm.ShipmentProductQuantity =this.shipmentForm.Box.PiecesInOddBox + (this.shipmentForm.Box.PiecesInBox * this.shipmentForm.BoxQuantity);
      }
    },
    checkTotalProduction() {
      if (this.project.Production) {
        if (this.project.Production.TotalProductionDetails.FinalProductionQuantity == this.project.Production.TotalProductionDetails.OddBoxes.reduce((accumulator,box) =>{ return accumulator +(box.BoxQuantity * box.PiecesInBox) },0) + (this.project.Production.TotalProductionDetails.BoxQuantity * this.project.Production.TotalProductionDetails.Box.PiecesInBox)) {
          this.isTotalProductionValid = true;
        } else {
          this.isTotalProductionValid = false;
        }
      }
    },
    checkShippmentQuantity() {
      this.shipmentForm.BoxQuantity=(this.shipmentForm.ShipmentProductQuantity - this.shipmentForm.OddBoxes.reduce((accumulator,box) =>{ return accumulator +(box.BoxQuantity * box.PiecesInBox) },0)) / this.shipmentForm.Box.PiecesInBox;
      console.log(this.shipmentForm.Box.PiecesInBox)
      console.log(this.shipmentForm.BoxQuantity)
      if (this.shipmentForm) {
        if (this.shipmentForm.ShipmentProductQuantity == this.shipmentForm.OddBoxes.reduce((accumulator,box) =>{ return accumulator +(box.BoxQuantity * box.PiecesInBox) },0) + (this.shipmentForm.Box.PiecesInBox * this.shipmentForm.BoxQuantity)) {
          this.isShippmentQuantityValid = true;
        } else {
          this.isShippmentQuantityValid = false;
        }
      }
    },
    saveCustomerInvoiceInfos(){
      this.projectValidation = [];
      this.loading = "saveCustomerInvoice";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          JobId: this.id,

          CustomerInvoiceDate: this.project.CustomerInvoiceInfos.CustomerInvoiceDate,
          CustomerInvoiceNumber: this.project.CustomerInvoiceInfos.CustomerInvoiceNumber,
          CustomerInvoiceQuantity: this.project.CustomerInvoiceInfos.CustomerInvoiceQuantity
        };
        var res = await this.$client.post("/Job/SaveCustomerInvoiceInfos", payload);
        this.projectValidation = res.data.ValidationErrors;
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
         await this.getProject();
        }
        this.loading = false;
      }, 500);
    },
    getClassDesingStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "info";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "success";
          break;
        case 3:
          res = "success";
          break;
        case 4:
          res = "warning";
            break;
        case 5:
          res = "warning";
            break;
      }
      return res;
    },
    getClassProductionStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "warning";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "success";
          break;
        case 4:
          res = "danger";
            break;
      }
      return res;
    },
    getClassOperationStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "warning";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "success";
          break;
        case 4:
        res = "success";
          break;
      }
      return res;
    },
    getClassAccountStatus(status){
      console.log(status)
      var res;
      switch (status) {
        case false:
          res = "warning";
          break;
        case true:
          res = "success";
          break;
      }
      return res;
    },
    getClassQualityControlStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "info";
          break;
        case 1:
          res = "danger";
          break;
        case 2:
          res = "success";
          break;
      }
      return res;
    },
    getTransferClassStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "info";
          break;
      }
      return res;
    },
  },
  computed: {
    getUserList() {
      return this.$store.getters.getUserList;
    },
    agentList() {
      return this.$store.getters.getAgentList;
    },
    user() {
      return this.$store.getters.getUser;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    suppliersList() {
      return this.$store.getters.getSuppliersList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    productList() {
      return this.$store.getters.getProductsList;
    },
    getWarehouseList() {
      return this.$store.getters.getWarehouseList;
    },
  },
};
</script>
